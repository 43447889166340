import React from "react";
import { useParams } from "react-router-dom";

const SubHeaderMenu = ({ location }) => {
  const MenuTitle = [
    {
      path: "/dashboard",
      title: "Dashboard",
    },
    {
      path: "/MyProfile",
      title: " My Profile",
    },
    {
      path: "/cashfuseCustomer",
      title: "CashFuse Customer",
    },
    {
      path: "/myTransection",
      title: "My Transection",
    },
    {
      path: "/kycComponents",
      title: "KYC Complete",
    },
    {
      path: "/wallet",
      title: "Total Amout Withdraw",
    },
    {
      path: "/cashFuseOffer",
      title: "CashFuse Offer",
    },
    {
      path: "/addOffer",
      title: "Add Offer",
    },
    {
      path: "/conditions",
      title: "Terms & Conditions",
    },
    {
      path: "/help",
      title: "Help",
    },
    {
      path: "/createbanner",
      title: "CashFuse Banner",
    },
  ];
  const pathname = location.pathname;
  const params = useParams();

  const current_title = MenuTitle.filter((menu) => pathname === menu.path);
  return <>{current_title.length > "0" ? current_title[0].title : params ? "landing Page" : "Cash Fuse Page"}</>;
};

export default SubHeaderMenu;
