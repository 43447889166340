import React from 'react';
import useInView from '../../Common/useInView';
import { Link } from 'react-router-dom';
import StoreCard from '../../Common/Cards/StoreCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
const TrendingStore = ({ TopOfferData }) => {
    const [ref, isInView] = useInView();

    console.log("tyip", TopOfferData)


    const merchants = TopOfferData?.merchant || [];
    const offers = TopOfferData?.offers || [];
    console.log(merchants, offers, "merchants")

    const BASE_URL = "https://liveuat.com:5438";
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className="lg:py-10" ref={ref}>
            <div className="container m-auto">
                <div className="lg:mb-10 p-4 lg:flex md:flex sm:flex justify-between items-center">
                    <h2
                        className={`font-bold text-custom-blue lg:text-[40px] text-[30px] md:text-[35px] ${isInView ? 'animate-fadeInLeft opacity-100' : 'opacity-1'
                            }`}
                        style={{ animationDelay: '0s' }}
                    >
                        Trending CashFuse Store
                    </h2>

                    <Link to="/allcashfusestore" state={{ TopOfferData }}>
                        <span className='text-custom-red'>See More</span>
                    </Link>
                </div>

                <div className="">
                    <Slider {...settings}>
                        {offers.map((trendingStore) => {

                            const merchantDetails = merchants.find(
                                merchant => merchant._id === trendingStore.merchantId?._id
                            ); 
                            const shopNameStore = merchantDetails?.businessDetils?.shopName || "Shop Name";
                            const Address = merchantDetails?.businessDetils?.businessAddress || "Address not available";
                            const distance = "N/A"; // Add logic for distance if needed
                            const TopStoreOffer = trendingStore.storeOffer || "0%";

                            const shopImages = merchantDetails?.businessDetils?.shopImage || [];
                            const shopImage = shopImages.length > 0 ? `${BASE_URL}/${shopImages[0]}` : null;
                            const MerId = merchantDetails?._id;
                            console.log(MerId, "MerId")

                            return (
                                <StoreCard
                                    key={merchantDetails?._id}
                                    shopName={shopNameStore}
                                    storeOffer={TopStoreOffer}
                                    address={Address}
                                    distance={distance}
                                    logo={shopImage}
                                    buttonText="Shop Now"
                                    buttonClass="bg-orange-600 text-white px-6 py-2 rounded-full text-sm hover:bg-orange-500 absolute bottom-3 right-3"
                                    link="/shopdetail"
                                    state={{
                                        MerId: MerId 
                                    }}
                                />
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default TrendingStore;
