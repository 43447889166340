import React, { useEffect, useState } from 'react';
import useNetworkServices from '../../../Networking/baseAPIManager';
import StorBgImg from '../../../assets/images/slider/stor_bg.png';
import { useNavigate } from 'react-router-dom';
import CategoryCard from "../../Common/Cards/CategoryCard";

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CashFuseStore = ({ TopOfferData }) => {
    const { CategorylistUser } = useNetworkServices();
    const [categories, setCategories] = useState([]);
    const BASE_URL = "https://liveuat.com:5438";
    const [activeCategory, setActiveCategory] = useState(null);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const navigate = useNavigate();
    const { lat, long } = TopOfferData.userLocation;


    


    console.log(activeCategory,"activeCategory")
    useEffect(() => {
        const fatchCategory = async () => {
            try {
                const response = await CategorylistUser();
                if (response?.data?.data) {
                    setCategories(response.data.data);
                    setFilteredCategories(response.data.data);
                }
            } catch (error) {
                console.error("category error", error);
            }
        };

        fatchCategory();
    }, []);

    const handleCategoryClick = (categoryId) => {
        setActiveCategory(categoryId);
        const filteredData = categories.filter(category => category._id === categoryId);
        setFilteredCategories(filteredData);

        // Pass activeCategory and LocationData as state to the Offer page
        navigate("/offer", { state: { activeCategory: categoryId, storeOffer: filteredData, locationData: { lat, long }, } });

    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5, // Default number of slides to show
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 6, // Show 6 slides for larger screens
                },
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 5, // Adjust to 5 for medium-large screens
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4, // 4 slides for standard desktops
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4, // Same as 1200px to keep uniformity
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3, // Compact view for tablets
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2, // Small view for mobile devices
                },
            },
            {
                breakpoint: 480, // Combines smaller screen ranges
                settings: {
                    slidesToShow: 2, // Better fit for small mobile devices
                },
            },
            {
                breakpoint: 376,
                settings: {
                    slidesToShow: 1, // Single slide for very small devices
                },
            },
        ],
    };




    
    return (
        <>


            {/* Section with background image */}
            <section className='  w-full lg:pt-20 pt-10 lg:pb-16 pb-10 mt-[-20px] bg-center lg:bg-auto md:bg-auto' style={{ background: `url(${StorBgImg})`, backgroundPosition: 'center' }}>
                <div className="container m-auto">
                    <div className='lg:pb-10 md:pb-10 sm:pb-10 animate-fadeInUp'>
                        <h2 className='text-center font-bold text-white lg:text-[40px] text-[30px] md:text-[35px]'>Find Your Offers</h2>
                    </div>
                </div>
            </section>

            {/* Categories Grid */}
            <div className="container m-auto">
                <div className='mt-[-30px] lg:mt-[-70px] md:mt-[-60px]'>
                   
                    <Slider {...sliderSettings}>
                        {categories.map((category) => (
                            <CategoryCard
                                key={category._id}
                                category={category}
                                categoriesData={filteredCategories}
                                onCategoryClick={handleCategoryClick}
                                activeCategory={activeCategory}
                                BASE_URL={BASE_URL}
                            />
                        ))}
                    </Slider>
                </div>
            </div>
        </>
    );
};

export default CashFuseStore;
