import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar/Sidebar';

const PrivateLayout = ({ onProfileUpdate, profileImage, profileName }) => {
    let token = sessionStorage.getItem("token")
    let isLogin = token;


    console.log(profileImage, 'profileImagexzcxzc')

    return (
        <div>
            <Header profileImage={profileImage} profileName={profileName} />
            <Sidebar />
            <div>
                {isLogin ? <Outlet context={{ onProfileUpdate }} /> : < Navigate to="/signin" />}
            </div>
        </div>
    );
}

export default PrivateLayout;
