import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SubHeaderMenu from "./Sidebar/SubHeaderMenu";
import useNetworkServices from "../Networking/baseAPIManager";

export const Help = () => {
  const location = useLocation();
  const { Getfaqs, AddFaqs } = useNetworkServices();
  const [question, setQuestion] = useState("");
  const [asnswer, setAsnswer] = useState("");
  const [faqs, setFaqs] = useState([]);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    fetchTerms();
  }, []);
  const validateForm = () => {
    const errors = {};
    if (!question.trim()) {
      errors.question = "Question is required.";
    }
    if (!asnswer.trim()) {
      errors.asnswer = "Answer is required.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const fetchTerms = async () => {
    try {
      const response = await Getfaqs();
      if (response.status === 200) {
        setFaqs(response.data.data.question);
      }
    } catch (error) {
      console.error("Error fetching FAQs:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const newFaq = {
      question: question,
      answer: asnswer,
    };

    const payload = {
      question: [newFaq],
    };

    try {
      const response = await AddFaqs(payload);
      if (response.status === 200) {
        console.log(response.data, "questions");
      }
      setQuestion("");
      setAsnswer("");
      setErrors({});
      fetchTerms();
    } catch (error) {
      console.error("Error submitting FAQ:", error);
      alert("Failed to submit FAQ. Please try again.");
    }
  };

  return (
    <>
      <div className="p-4" id="main">
        <h1 className="font-bold text-2xl text-slate-900 pb-5">
          <SubHeaderMenu location={location} />
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="p-6 flex-1">
            <h1 className="text-2xl font-bold mb-4">Add Question & Answer</h1>
            <div className="grid grid-cols-2 gap-4 mb-6">
              <div>
                <label htmlFor="question" className="block text-sm font-bold text-gray-700 mb-1">
                  Add Question
                </label>
                <input
                  type="text"
                  id="question"
                  placeholder="Add the Question"
                  className="border p-2 rounded w-full"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
                {errors.question && <p className="text-red-500 text-sm">{errors.question}</p>}
              </div>
              <div>
                <label htmlFor="answer" className="block text-sm font-bold text-gray-700 mb-1">
                  Add Answer
                </label>
                <input
                  type="text"
                  id="answer"
                  placeholder="Add the Answer"
                  className="border p-2 rounded w-full"
                  value={asnswer}
                  onChange={(e) => setAsnswer(e.target.value)}
                />
                {errors.asnswer && <p className="text-red-500 text-sm">{errors.asnswer}</p>}
              </div>
            </div>
            <div className="xl:flex lg:flex md:flex sm:flex justify-end gap-4 pt-5 xl:pt-0 lg:pt-0 mb-5">
              <button
                type="submit"
                className="border-custom-red border hover:border-custom-red hover:text-white text-custom-red font-bold py-2 px-4 rounded-lg hover:bg-custom-red transition duration-300"
              >
                Submit
              </button>
            </div>
          </div>
         
        </form>

        <div className="p-6 flex-1">
          {faqs.length > 0 ? (
            faqs.map((faq, index) => (
              <div key={index} className="grid grid-cols-2 gap-4 mb-6">
                <div className="bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5">
                  <span className="font-bold mr-2">{index + 1}.</span>
                  {faq.question}
                </div>
                <div className="bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5">
                  {faq.answer}
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500">No FAQs available.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default Help;
