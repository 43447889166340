import React from "react";
import TestimonialSlider from "../../Common/TestimonialSlider"; 
export const Testimonial = () => {
  return (
    <div className=" text-black lg:pb-20 pb-10 pt-8">
      <div className="container mx-auto flex flex-col lg:flex-row items-center">
        {/* Left Content */}
        <div className="grid grid-cols-12">
          <div className=" col-span-12 lg:col-span-6 px-8">
            <h4 className="text-sm sm:text-base lg:text-lg text-custom-orenge pb-4">Clients</h4>

            <h2 className='font-bold text-custom-blue lg:text-[40px] text-[30px] md:text-[35px]'>
              Happy with   <span className="hidden sm:inline"> <br /> </span>
              Customers & Clients
            </h2>

            <p className="mb-6 text-gray-500 text-[14px]">
              Our clients are the heart of our business. We take pride in offering tailored industrial solutions that meet their unique needs. Whether it's cutting-edge technology, reliable services, or innovative strategies, we're here to support your growth and success.
            </p>
           

          </div>
          {/* Right Slider */}
          <div className=" col-span-12  lg:col-span-6    ">
            <TestimonialSlider />
          </div>
        </div>
       
      </div>
    </div>
  );
};
