
const endpoints = {
    SIGNUP: "merchant/sign_up",
    SIGNIN: "merchant/login",
    FORGOTPASSWORD: "merchant/forgot_password",
    OTPVERIFICATION: "merchant/verify_otp",
    RESENDOTP: "merchant/resendOtp",
    RESETPASSWORD: "merchant/reset_Password",
    CASHFUSECUSTOMER: "merchant/casefuse_customer",
    GETPROFILE: "merchant/get_profile",
    UPDATEPROFILE: "merchant/update_profile",
    TRANSECTION: "merchant/payment_history",
    CATEGORYLIST: "merchant/category_list",
    CATEGORYLISTUSER: "user/category_list?page=1&limit=10",
    CONTACTUS: "user/contact_us",
    ADDOFFER: "merchant/add_offer",
    GETOFFER: "merchant/get_offer",
    TERMSCONDATION: "merchant/add_terms",
    GETTREAM: "merchant/get_terms",
    AddFAQ: "merchant/addFaq",
    GETFAQ: "merchant/getFaq",
    ADDOFFERUPDATE: "merchant/update_offer",
    ADDBANNER: "/merchant/add_banner",
    GETBANNER: "/merchant/get_banner",
    ADDBANNERUODATE: "/merchant/update_banner",
    // GETBANNER:"/merchant/get_banner",
    GETTOPOFFER: "/user/homepage",
    DASHBOARD: "/merchant/dashBoard",
    WALLET: "/merchant/withdraw_history",
    WITHDRAWAMOUNT: "/merchant/withdrawAmount",
    MRCHANT_DETAILS:"user/merchant_details",
    MERCHANTSTORE:"/user/merchants",
    GET_MERCHANT_BANNER:"/user/get_banner"


};

export { endpoints };
