
import { useCallback } from "react";
import api from "./authAxios";
import { endpoints } from "./endpoints"; // Ensure the path is correct

const useNetworkServices = () => {
    const apiWithAuth = api;

    const signUpMerchant = useCallback(
        async (data) => {
            return apiWithAuth.post(endpoints.SIGNUP, data);
        },
        [apiWithAuth]
    );


    const signInMerchant = useCallback(
        async (data) => {
            return apiWithAuth.post(endpoints.SIGNIN, data);
        }, [apiWithAuth]
    );


    const ForgotPassword = useCallback(
        async (data) => {
            return apiWithAuth.post(endpoints.FORGOTPASSWORD, data);
        }, [apiWithAuth]
    );


    const OTPVerification = useCallback(
        async (data) => {
            return apiWithAuth.post(endpoints.OTPVERIFICATION, data);
        }, [apiWithAuth]
    )


    const ResendOTP = useCallback(
        async (data) => {
            return apiWithAuth.post(endpoints.RESENDOTP, data);
        }, [apiWithAuth]
    )


    const ResetPassword = useCallback(
        async (data) => {
            return apiWithAuth.post(endpoints.RESETPASSWORD, data);
        }, [apiWithAuth]
    )

    const CashfuseCustomers = useCallback(
        async () => {
            return apiWithAuth.get(endpoints.CASHFUSECUSTOMER);
        }, [apiWithAuth]
    )

    const GetProfile = useCallback(
        async () => {
            return apiWithAuth.get(endpoints.GETPROFILE);
        }, [apiWithAuth]
    )

    const UpdateProfile = useCallback(
        async (data) => {
            return apiWithAuth.patch(endpoints.UPDATEPROFILE, data);
        }, [apiWithAuth]
    )
    const MyTransection = useCallback(
        async () => {
            return apiWithAuth.get(endpoints.TRANSECTION);
        }, [apiWithAuth]
    )

    const Categorylist = useCallback(
        async () => {
            return apiWithAuth.get(endpoints.CATEGORYLIST);
        }, [apiWithAuth]
    )
    const ContactUsData = useCallback(
        async (data) => {
            return apiWithAuth.post(endpoints.CONTACTUS, data);
        }, [apiWithAuth]
    )
    const AddCashOffer = useCallback(
        async (data) => {
            return apiWithAuth.post(endpoints.ADDOFFER, data);
        }, [apiWithAuth]
    )
    const GetCashOffer = useCallback(
        async () => {
            return apiWithAuth.get(endpoints.GETOFFER);
        }, [apiWithAuth]
    )
    const TreamCondation = useCallback(
        async (data) => {
            console.log(data, "data");

            return apiWithAuth.post(endpoints.TERMSCONDATION, data);

        }, [apiWithAuth]
    )
    const GetTreams = useCallback(
        async () => {
            return apiWithAuth.get(endpoints.GETTREAM);
        }, [apiWithAuth]
    )
    const AddFaqs = useCallback(
        async (data) => {
            return apiWithAuth.post(endpoints.AddFAQ, data);
        }, [apiWithAuth]
    )
    const Getfaqs = useCallback(
        async () => {
            return apiWithAuth.get(endpoints.GETFAQ);
        }, [apiWithAuth]
    )
    const AddofferUpdate = useCallback(
        async (data) => {
            return apiWithAuth.patch(endpoints.ADDOFFERUPDATE, data);
        }, [apiWithAuth]
    )
    const AddBanner = useCallback(
        async (data) => {
            return apiWithAuth.post(endpoints.ADDBANNER, data);
        }, [apiWithAuth]
    )
    const GetBanner = useCallback(
        async () => {
            return apiWithAuth.get(endpoints.GETBANNER);
        }, [apiWithAuth]
    )
    const AddBannerUpload = useCallback(
        async (data) => {
            return apiWithAuth.patch(endpoints.ADDBANNERUODATE, data);
        }, [apiWithAuth]
    )
    const GetTopOffer = useCallback(
        async ({params}) => {
            return apiWithAuth.get(endpoints.GETTOPOFFER,{params});

        }, [apiWithAuth]
    )
    const GetDashboard = useCallback(
        async ({ params }) => {
            return apiWithAuth.get(endpoints.DASHBOARD, { params });

        }, [apiWithAuth]
    )

    const GetMerchantsStore = useCallback(
        async ({ params}) => { 
            return apiWithAuth.get(endpoints.MERCHANTSTORE, { params })
    },[apiWithAuth]
    )
    
    const GetMerchantDetails = useCallback(
        async ({ params }) => {
            return apiWithAuth.get(endpoints.MRCHANT_DETAILS,{params})
        },[apiWithAuth]
    )

    const Getwallet = useCallback(
        async () => {
            return apiWithAuth.get(endpoints.WALLET);

        }, [apiWithAuth]
    )

    const CategorylistUser = useCallback(
        async () => {
            return apiWithAuth.get(endpoints.CATEGORYLISTUSER);
        },[apiWithAuth]
    )

    const WithDrawAmonut = useCallback(
        async (data) => {
            return apiWithAuth.post(endpoints.WITHDRAWAMOUNT, data);

        }, [apiWithAuth]
    )

    const GetMerchantBanner = useCallback(
        async () => {
            return apiWithAuth.get(endpoints.GET_MERCHANT_BANNER);
        },[apiWithAuth]
    )



    return {
        signUpMerchant,
        signInMerchant,
        ForgotPassword,
        OTPVerification,
        ResendOTP,
        ResetPassword,
        CashfuseCustomers,
        GetProfile,
        UpdateProfile,
        MyTransection,
        Categorylist,
        ContactUsData,
        AddCashOffer,
        GetCashOffer,
        TreamCondation,
        GetTreams,
        AddFaqs,
        Getfaqs,
        AddofferUpdate,
        AddBanner,
        GetBanner,
        AddBannerUpload,
        GetTopOffer,
        GetDashboard,
        Getwallet,
        WithDrawAmonut,
        CategorylistUser,
        GetMerchantsStore,
        GetMerchantBanner,
        GetMerchantDetails


    };
};

export default useNetworkServices;
