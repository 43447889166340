 

import React from "react";
import { ToastContainer , toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sucessImg from "../../assets/images/dashboardImg/success.gif";
 

export const showToast = (message, type = "default", customClass = "") => {
  const toastTypes = {
    success: () =>
      toast.success(message, {
        className: `${customClass} bg-green-700 text-white rounded-lg shadow-md`,
      }),
    error: () =>
      toast.error(message, {
        className: `${customClass} bg-red-500 text-white rounded-lg shadow-md`,
      }),
    info: () =>
      toast.info(message, {
        className: `${customClass} bg-blue-500 text-white rounded-lg shadow-md`,
      }),
    warning: () =>
      toast.warn(message, {
        className: `${customClass} bg-yellow-500 text-black rounded-lg shadow-md`,
      }),
    default: () =>
      toast(message, {
        className: `${customClass} bg-gray-800 text-white rounded-lg shadow-md`,
      }),
    complete: () =>
      toast(
        <div className="flex flex-col items-center">
          <img src={sucessImg} alt="check" className="w-16 h-16 mb-2" />
          <span>{message}</span>
        </div>,
        {
          className: `${customClass} bg-blue-500 text-white text-center p-4 rounded-xl shadow-lg`,
        }
      ),
  };


 
  // Fallback to default if type is not recognized
  (toastTypes[type] || toastTypes.default)();
};
export const Toastify = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={2000}
      // hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      // pauseOnFocusLoss
      // draggable
      pauseOnHover
      theme="colored"
    />
  );
};
