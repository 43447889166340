import React, { useEffect, useState } from "react";
import SubHeaderMenu from "./Sidebar/SubHeaderMenu";
import { useLocation } from "react-router-dom";
import Button from "./Common/Button";
import { Table } from "./Common/Table/Table";
import useNetworkServices from "../Networking/baseAPIManager";
import RedeemModal from "./Common/RedeemModal ";


export const Wallet = () => {
  const location = useLocation();
  const { Getwallet } = useNetworkServices();
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  console.log(tableData, "tableData");
  const moment = require("moment");

  const columns = [
    { header: "S. No", accessor: "sNo" },
    { header: "Transaction ID", accessor: "shortId" },
    { header: "Date", accessor: "formattedDate" },
    { header: "Time", accessor: "formattedTime" },
    { header: "Bank Name", accessor: "bankName" },
    { header: "Account No", accessor: "accountNo" },
    { header: "Reward Points", accessor: "reward_points" },
    // { header: "Balance", accessor: "balanceAfter" },
  ];

  const handleRedeemClick = () => {
    setIsModalOpen(true);
  };

  const balanceBefore = tableData?.redeemAmount || "N/A";


  const fetchTransactions = async () => {
    try {
      const response = await Getwallet();
      const formattedUsers = response.data.data.users.map((user, index) => ({
        ...user,
        sNo: index + 1,
        shortId: user._id.slice(-4),
        formattedDate: moment(user.timestamp).format("YYYY-MM-DD"),
        formattedTime: moment(user.timestamp).format("hh:mm A"),
      }));
      setTableData({ ...response.data.data, users: formattedUsers });
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };
  useEffect(() => {

    fetchTransactions();
  }, [Getwallet]);

  const totalRows = tableData?.users?.length || 0;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = tableData?.users?.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  return (
    <div id="main" className="p-4">
      <h1 className="font-bold text-2xl text-slate-900 pb-5">
        <SubHeaderMenu location={location} />
      </h1>
      <div className="grid grid-cols-12">
        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6 p-4 sm:border-r-[0px] xl:border-r-[1px] xl:border-b-[1px]">
          <div className="md:flex sm:flex justify-between ">
            <div className="dashboard_content">
              <h3 className="text-2xl font-bold text-gray-800 mb-2">CashFuse: {balanceBefore}</h3>
            </div>
            <div>
              <Button
                className="bg-custom-blue text-white font-bold py-2 px-4 rounded hover:bg-custom-blue2 transition duration-300"
                text={"Redeem Now"}
                onClick={handleRedeemClick}
              />
            </div>
          </div>
        </div>

        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-6 lg:pb-4 sm:pb-4 sm:border-0 lg:border-b-[1px] xl:col-span-6 xl:border-b-[1px] px-4">
          <div className="flex justify-between pt-4">
            <div className="dashboard_content">
              <h6 className="text-sm font-bold text-gray-600 mb-2">INR: 000</h6>
            </div>
          </div>
        </div>
      </div>
      {/* <Table columns={columns} data={tableData?.users} />  */}
      {/* {isModalOpen && <RedeemModal onClose={() => setIsModalOpen(false)} fetchTransactions={fetchTransactions} />} */}
      <Table columns={columns} data={paginatedData} />
      {/* Pagination Controls */}
      {totalPages > 1 && (
        <div className="flex justify-center mt-4">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`mx-1 px-3 py-1 rounded ${currentPage === index + 1
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-800"
                }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
      {isModalOpen && (
        <RedeemModal
          onClose={() => setIsModalOpen(false)}
          fetchTransactions={fetchTransactions}
        />
      )}
    </div>
  );
};
