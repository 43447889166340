import React, { useEffect, useState } from "react";
import BackgroundImage from "../../assets/images/img/SIGNUP-BG.png";
import LoginImg from "../../assets/images/img/login.png";
import Button from "../Common/Button";
import { useNavigate} from "react-router-dom";
import useNetworkServices from "../../Networking/baseAPIManager";
import { HeaderLogo } from "./HeaderLogo";
import { showToast } from "../Common/Toastify";

const OTPVerification = () => {
  const otpLength = 4;
  const initialTime = 2 * 60; // 1 minute in seconds
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(initialTime);
  const [otp, setOtp] = useState(Array(otpLength).fill());
  const [isExpired, setExpired] = useState(false);
  const { OTPVerification, ResendOTP } = useNetworkServices();
  const [formData, setFormData] = useState({ email: "" });
  const [error, setError] = useState("");
  useEffect(() => {
    const email = localStorage.getItem("forgotPasswordEmail");
    if (email) {
      setFormData({ email });
    }

    const savedExpiryTime = localStorage.getItem("otpExpiryTime");
    if (savedExpiryTime) {
      const timeRemaining = Math.floor((savedExpiryTime - Date.now()) / 1000);
      if (timeRemaining > 0) {
        setSeconds(timeRemaining);
        setExpired(false);
      } else {
        setSeconds(0);
        setExpired(true);
      }
    } else {
      const expiryTime = Date.now() + initialTime * 1000;
      localStorage.setItem("otpExpiryTime", expiryTime);
    }
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds <= 1) {
          clearInterval(timer);
          setExpired(true);
          localStorage.removeItem("otpExpiryTime");
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);
    return () => clearInterval(timer);
  }, [initialTime]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < otpLength - 1 && value) {
        const nextInput = document.getElementById(`otp-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      if (index > 0) {
        const prevInput = document.getElementById(`otp-${index - 1}`);
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isExpired) {
      setError("");
      return;
    }

    const otpCode = otp.join("");
    const data = { email: formData.email, otp: otpCode };

    console.log("Sending data:", data); // Debugging line
    try {
      const response = await OTPVerification(data);
      console.log(response.data, "ResponseData");
      if (response.data.success) {
        const otpToken = response.data.otpToken;
        navigate("/newpassword", { state: { otpToken } });
        console.log(otpToken, "otpToken");
      } else {
        setError("Fill the Blank Otp");
      }
    } catch (error) {
      console.error("Sign-in error:", error);
      setError("An error occurred. Please try again.");
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await ResendOTP(formData);
      console.log("OTP resent successfully:", response.data);
      setOtp(Array(otpLength).fill("")); // Clear the OTP input
      const expiryTime = Date.now() + initialTime * 1000;
      localStorage.setItem("otpExpiryTime", expiryTime);
      setSeconds(initialTime);
      setExpired(false);
    } catch (error) {
      // console.error("Error resending OTP:", error);
      setError("Failed to resend OTP. Please try again.");
      const apiMessage =
        error.response?.data?.message || "Something went wrong. Please try again.";
      showToast(apiMessage, "error"); 
    }
  };

  return (
    <>
      <div
        className="items-center justify-center min-h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      >
     
          <HeaderLogo />
       
        <div className="flex items-center justify-center  ">
        <div className="container p-8   lg:m-0 md:m-0">
          <div className="grid grid-cols-12 gap-8">
            <div className="col-span-12 md:col-span-6 lg:col-span-6 flex items-center justify-center">
              <div className="bg-white rounded-lg shadow md:mt-0 sm:max-w-lg xl:p-0 dark:bg-white dark:border-gray-700">
                <div className="p-8 space-y-3 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-800">
                    OTP Verification
                  </h1>
                  <p className="text-gray-400 text-sm">
                    Please enter the OTP sent to your email to verify your account.
                  </p>
                  {error && <p className="text-red-500 text-sm">{error}</p>}
                  <form onSubmit={handleSubmit}>
                    <div className="flex flex-col space-y-8">
                      <div className="flex flex-row items-center justify-between mx-auto w-full">
                        {Array.from({ length: otpLength }).map((_, index) => (
                          <div key={index} className="w-16 h-16">
                            <input
                              className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                              type="text"
                              name={`otp-${index}`}
                              id={`otp-${index}`}
                              maxLength="1"
                              value={otp[index]}
                              onChange={(e) => handleChange(e, index)}
                              onKeyDown={(e) => handleKeyDown(e, index)}
                            />
                          </div>
                        ))}
                      </div>

                      <div className="flex flex-col space-y-5">
                        {isExpired ? (
                          <p className="text-red-500">OTP has expired. Please request a new one.</p>
                        ) : (
                          <p>
                            Time remaining: {Math.floor(seconds / 60)}:{String(seconds % 60).padStart(2, "0")}
                          </p>
                        )}
                        <div>
                          <Button
                            type="submit"
                            className="bg-custom-blue w-full text-white font-bold py-2 px-4 rounded hover:bg-custom-blue2 transition duration-300 "
                            text="Verify Account"
                          />
                        </div>

                        <div className="flex flex-col items-center text-center text-sm font-medium space-y-1 text-gray-500">
                          <div className="flex pt-3">
                            <p className="pe-2">Didn't receive code?</p>
                            <button
                              type="button"
                              className="flex flex-row items-center text-blue-600"
                              onClick={handleResendOtp}
                              disabled={!isExpired}
                            >
                              Resend OTP
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-6 flex items-center justify-center">
              <img src={LoginImg} alt="Login" className="max-w-full h-auto" />
            </div>
          </div>
          </div>
          </div>
      </div>
    </>
  );
};

export default OTPVerification;
