// import React, { useEffect, useState } from "react";
// import Button from "../Common/Button";
// import useNetworkServices from "../../Networking/baseAPIManager";
// import { useNavigate } from "react-router-dom";
// import { FaFileUpload } from "react-icons/fa";

// const MyBusiness = ({
//   profileData,
//   onImageChange, 
//   onProfileUpdate,
// }) => {
//   const { UpdateProfile, Categorylist } = useNetworkServices();
//   const navigate = useNavigate();
//   const [categories, setCategories] = useState([]);
//   const [fileName, setFileName] = useState("");
//   const [workingHour, setWorkingHour] = useState("");
//   const [brandFileName, setBrandFileName] = useState("");
//   const [formData, setFormData] = useState({
//     shopImage: [{}],
//     shopName: "",
//     gstNumber: "",
//     brandImage: "",
//     workingHours: "",
//     business_email: "",
//     business_mobileNo: "",
//     category: "",
//     businessAddress: "",
//     businessPinCode: "",
//     businessCity: "",
//     businessState: "",
//     // image: profileImage,
//     aboutUs: "",
//   });

//   console.log(formData, "formData==>");
//   const [error, setErrors] = useState({});

//   useEffect(() => {
//     if (profileData) {
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         // businessLogo: profileData?.businessDetils?.logo || "",
//         shopName: profileData?.businessDetils?.shopName || "",
//         workingHours: profileData?.businessDetils?.workingHours || "",

//         gstNumber: profileData?.businessDetils?.gstNumber || "",
//         brandImage: profileData?.businessDetils?.brandImage || "",
//         business_email: profileData?.businessDetils?.business_email || "",
//         business_mobileNo: profileData?.businessDetils?.business_mobileNo || "",
//         category: profileData?.businessDetils?.category || "",
//         businessAddress: profileData?.businessDetils?.businessAddress || "",
//         businessPinCode: profileData?.businessDetils?.businessPinCode || "",
//         businessCity: profileData?.businessDetils?.businessCity || "",
//         businessState: profileData?.businessDetils?.businessState || "",
//         aboutUs: profileData?.businessDetils?.aboutUs || "",
//         // image: profileImage,
//       }));

//       setWorkingHour(
//         profileData?.businessDetils?.workingHours
//           ? profileData.businessDetils.workingHours.split("\\").pop()
//           : ""
//       );
//       setBrandFileName(profileData?.businessDetils?.brandImage);
//       setFileName(profileData?.businessDetils?.shopImage);
//     }
//   }, [
//     profileData,
//     // , profileImage
//   ]);

//   console.log(
//     profileData?.businessDetils?.workingHours,
//     profileData,
//     "profileworks===>"
//   );
//   useEffect(() => {
//     const fatchCategory = async () => {
//       try {
//         const response = await Categorylist();
//         if (response?.data?.data) {
//           setCategories(response?.data?.data);
//           console.log(response, "response22==>");
//         }
//       } catch (error) {
//         console.error("category error", error);
//       }
//     };

//     fatchCategory();
//   }, []);

//   const validateForm = () => {
//     const newErrors = {};

//     if (!formData.workingHours)
//       newErrors.workingHours = "working Hours is required";
//     if (!formData.shopName) newErrors.shopName = "Shop Name is required";
//     if (!formData.brandImage) newErrors.brandImage = "Tin Number is required";
//     if (!formData.gstNumber) newErrors.gstNumber = " GST Number is required";
//     if (
//       !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
//         formData.business_email
//       )
//     )
//       newErrors.business_email = "Invalid email businessAddress";
//     if (!formData.business_mobileNo) {
//       newErrors.business_mobileNo = "Phone Number is Required";
//     }
//     if (!formData.businessAddress)
//       newErrors.businessAddress = "businessAddress is required";
//     if (!formData.category) newErrors.category = "Select Category ";
//     if (!/^\d{6}$/.test(formData.businessPinCode))
//       newErrors.businessPinCode =
//         "businessPinCode must be a valid 6-digit number";
//     if (!formData.businessCity)
//       newErrors.businessCity = "businessCity is required";
//     if (!formData.businessState)
//       newErrors.businessState = "businessState is required";
//     if (!formData.aboutUs) newErrors.aboutUs = "AboutUs is required";

//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleChange = (e) => {
//     const { name, value, files } = e.target;
//     setFormData({
//       ...formData,
//       [name]: files ? files[0] : value,
//     });
//     if (name === "image" && files) {
//       onImageChange(files[0]);
//     }
//   };

//   const handleFormSubmit = async (e) => {
//     e.preventDefault();

//     if (validateForm()) {
//       const data = new FormData();
//       Object.keys(formData).forEach((key) => {
//         data.append(key, formData[key]);
//       });
//       formData.shopImage.forEach((file) => {
//         data.append("shopImage", file);
//       });
//       try {
//         const response = await UpdateProfile(data);

//         if (response.data && response.data.photo) {
//           setFormData((prevFormData) => ({
//             ...prevFormData,
//             image: response.data.photo,
//           }));
//           onImageChange(response.data.photo);
//         }
//         console.log(response.data, "ResponseData");
//         onProfileUpdate();
//         navigate("/MyProfile#bank-details");
//       } catch (error) {
//         console.error("Update Profile error:", error);
//       }
//     }
//   };

//   const handleFileChange = (e) => {
//     const { name, value, files } = e.target;

//     if (files) {
//       const selectedFiles = Array.from(files);
//       if (selectedFiles.length > 5) {
//         setErrors({
//           ...error,
//           shopImage: "You can only upload a maximum of 5 files.",
//         });
//         return;
//       }

//       const names = selectedFiles.map((file) => file.name);
//       setFileName(names);
//       setFormData((prevData) => ({ ...prevData, shopImage: selectedFiles }));
//       return;
//     }

//     setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
//   };

//   const [showTimePicker, setShowTimePicker] = useState(false);

//   const handleTimeChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       workingHours: {
//         ...prevData.workingHours,
//         [name]: value,
//       },
//     }));
//   };
//   const handleBrandImageChange = (e) => {
//     const { files } = e.target;

//     if (files && files.length > 0) {
//       const selectedFile = files[0]; // Get the first selected file
//       setBrandFileName(selectedFile.name); // Set the name of the selected file
//       setFormData((prevData) => ({ ...prevData, brandImage: selectedFile }));
//     }
//   };
//   const handleInputClick = () => {
//     setShowTimePicker(true); // Show the time inputs when the input is clicked
//   };

//   const handleTimeSelection = () => {
//     setShowTimePicker(false); // Hide the time pickers after selection
//   };


//   const handleCancel = () => {
//     setInitialValues({
//       shopImage: [{}],
//       shopName: "",
//       brandImage: '',
//       gstNumber: "",
//       workingHours: { from: "", to: "" },
//       brandImage: "",
//       business_email: "",
//       business_mobileNo: "",
//       category: "",
//       businessAddress: "",
//       businessPinCode: "",
//       businessCity: "",
//       businessState: "",
//       aboutUs: "",
//     });

//   };

//   const inputFields = [
//     { name: "category", type: "select", label: "Category" },
//     {
//       name: "shopName",
//       type: "text",
//       label: "Shop Name",
//       placeholder: "Shop Name",
//     },
//     // { name: 'brandImage', type: 'text', label: 'Brand Name', placeholder: 'Brand Name' },
//     {
//       name: "gstNumber",
//       type: "tel",
//       label: "GST Number",
//       placeholder: "GST Number",
//     },
//     {
//       name: "business_email",
//       type: "email",
//       label: "Email",
//       placeholder: "Email",
//     },
//     {
//       name: "business_mobileNo",
//       type: "tel",
//       label: "Phone Number",
//       placeholder: "Phone Number",
//     },
//     {
//       name: "businessAddress",
//       type: "text",
//       label: "Address",
//       placeholder: "Address",
//     },
//     {
//       name: "businessPinCode",
//       type: "tel",
//       label: "Pincode",
//       placeholder: "Pincode",
//     },
//     { name: "businessCity", type: "text", label: "City", placeholder: "City" },
//     {
//       name: "businessState",
//       type: "text",
//       label: "State",
//       placeholder: "State",
//     },
//     { name: "aboutUs", type: "text", label: "AboutUs", placeholder: "AboutUs" },
//   ];

//   return (
//     <div className="pt-8">
//       <form className="space-y-4 md:space-y-6" onSubmit={handleFormSubmit}>
//         <div className="grid grid-cols-1 sm:grid-cols-2 gap-7">
//           <div>
//             <label
//               htmlFor="businessLogo"
//               className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
//             >
//               Shop Image
//             </label>

//             <div className="w-full">
//               <input
//                 type="file"
//                 name="businessLogo"
//                 id="businessLogo"
//                 className="hidden"
//                 multiple
//                 onChange={handleFileChange}
//               />
//               <label
//                 htmlFor="businessLogo"
//                 className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 block w-full cursor-pointer flex items-center justify-between"
//               >
//                 <span className="flex items-center">
//                   {fileName.length > 0 ? fileName.join(", ") : "Choose file"}
//                 </span>
//                 <FaFileUpload className="mr-2" />
//               </label>
//             </div>
//             {error.businessLogo && (
//               <div className="text-red-500 text-sm">{error.businessLogo}</div>
//             )}
//           </div>
//           <div>
//             <label
//               htmlFor="brandImage"
//               className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
//             >
//               Brand Image
//             </label>

//             <div className="w-full">
//               <input
//                 type="file"
//                 name="brandImage"
//                 id="brandImage"
//                 className="hidden"
//                 onChange={handleBrandImageChange}
//               />
//               <label
//                 htmlFor="brandImage"
//                 className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 block w-full cursor-pointer flex items-center justify-between"
//               >
//                 <span className="flex items-center">
//                   {brandFileName ? brandFileName : "Choose file"}
//                 </span>
//                 <FaFileUpload className="mr-2" />
//               </label>
//             </div>
//             {error.brandImage && (
//               <div className="text-red-500 text-sm">{error.brandImage}</div>
//             )}
//           </div>
//           <div>
//             <label className="block mb-2 text-sm font-medium text-gray-900">
//               View Shop Hour
//             </label>
//             <input
//               type="text"
//               name="workingHours"
//               onClick={handleInputClick}
//               readOnly
//               value={`${formData.workingHours.from || workingHour}`}
//               placeholder="Select shop hours (e.g., 09:00 AM to 06:00 PM)"
//               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-pointer"
//             />

//             {showTimePicker && (
//               <div className="absolute bg-white border border-gray-300 p-4 rounded-lg mt-2 z-10">
//                 <div className="flex gap-4">
//                   <div className="w-1/2">
//                     <label className="block text-sm font-medium text-gray-900">
//                       From
//                     </label>
//                     <input
//                       type="time"
//                       name="from"
//                       value={formData.workingHours.from}
//                       onChange={handleTimeChange}
//                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
//                     />
//                   </div>
//                   <div className="w-1/2">
//                     <label className="block text-sm font-medium text-gray-900">
//                       To
//                     </label>
//                     <input
//                       type="time"
//                       name="to"
//                       value={formData.workingHours.to}
//                       onChange={handleTimeChange}
//                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
//                     />
//                   </div>
//                 </div>
//                 <button
//                   type="button"
//                   onClick={handleTimeSelection}
//                   className="mt-2 bg-blue-500 text-white py-1 px-4 rounded-lg"
//                 >
//                   Apply
//                 </button>
//               </div>
//             )}
//           </div>
//           {inputFields.map((field) => (
//             <div key={field.name}>
//               <label
//                 htmlFor={field.name}
//                 className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
//               >
//                 {field.label}
//               </label>
//               {field.type === "select" ? (
//                 <select
//                   name={field.name}
//                   id={field.name}
//                   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
//                   value={formData[field.name]}
//                   onChange={handleChange}
//                 >
//                   <option value="" disabled>
//                     Select Category
//                   </option>
//                   {categories.map((category) => (
//                     <option key={category._id} value={category._id}>
//                       {category.title}
//                     </option>
//                   ))}
//                 </select>
//               ) : (
//                 <input
//                   type={field.type}
//                   name={field.name}
//                   id={field.name}
//                   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
//                   placeholder={field.placeholder}
//                   value={formData[field.name]}
//                   onChange={handleChange}
//                   min={field.min}
//                   maxLength={field.maxLength}
//                 />
//               )}
//               {error[field.name] && (
//                 <div className="text-red-500 text-sm">{error[field.name]}</div>
//               )}
//             </div>
//           ))}
//         </div>

//         <div className="xl:flex lg:flex flex justify-end gap-4 pt-5 xl:pt-0 lg:pt-0">
//           <Button
//             type="button"
//             className="border-custom-red border hover:border-custom-red w-full mt-3 xl:mt-0 lg:mt-0 sm:mt-3 md:mt-3 hover:text-white text-custom-red font-bold py-2 px-4 rounded-lg hover:bg-custom-red transition duration-300"
//             text="Cancel"
//             onClick={handleCancel}
//           />
//           <Button
//             type="submit"
//             className="bg-custom-red w-full border text-white font-bold py-2 px-4 rounded-lg hover:text-custom-red hover:bg-transparent hover:border-custom-red hover:border transition duration-300"
//             text="Save Changes"
//           />
//         </div>
//       </form>
//     </div>
//   );
// };

// export default MyBusiness;








import React, { useEffect, useState } from "react";
import Button from "../Common/Button";
import useNetworkServices from "../../Networking/baseAPIManager";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { State, City } from 'country-state-city';
import { FaFileUpload } from "react-icons/fa";

const convertTo24Hour = (time) => {
  const [hoursMinutes, period] = time.split(" ");
  let [hours, minutes] = hoursMinutes.split(":").map(Number);

  if (period === "PM" && hours !== 12) {
    hours += 12;
  } else if (period === "AM" && hours === 12) {
    hours = 0;
  }

  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
};


const MyBusiness = ({ profileData, onImageChange, onProfileUpdate, }) => {
  const { UpdateProfile, Categorylist } = useNetworkServices();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [fileName, setFileName] = useState("");
  const [brandFileName, setBrandFileName] = useState("")
  const [states] = useState(State.getStatesOfCountry('IN')); // Fetch all states of India
  const [cities, setCities] = useState([]);

  const [initialValues, setInitialValues] = useState({
    shopImage: [],
    shopName: "",
    brandImage: null,
    gstNumber: "",
    workingHours: { from: "", to: "" },
    brandImage: "",
    business_email: "",
    business_mobileNo: "",
    category: "",
    businessAddress: "",
    businessPinCode: "",
    businessCity: "",
    businessState: "",
    aboutUs: "",
  });


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await Categorylist();
        if (response?.data?.data) {
          setCategories(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const validationSchema = Yup.object({
    shopName: Yup.string().required("Shop Name is required"),
    gstNumber: Yup.string()
      .required("GST Number is required")
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "Invalid GST Format"
      ),


    shopImage: Yup.array()
      .min(1, "At least one shop image is required")
      .required("Shop image is required"),


    brandImage: Yup.mixed().required("Brand Image is required"),
    workingHours: Yup.object().shape({
      from: Yup.string().required("Start time is required"),
      to: Yup.string().required("End time is required"),
    }),


    business_email: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[a-zA-Z0-9._%+-]{5,20}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/,
        'Email must have a min 5 and max 20 alphanumeric characters and must not contain text after the domain (e.g., ".com")'
      )
      .matches(
        /^(?!.*\.\w{2,}\.\w{2,}$)/,
        'Email must not have a double domain (e.g., ".com.us")'
      )
      .test(
        'length-check',
        'Email is too long or has an excessively long domain.',
        (value) =>
          !value || // Allow empty strings to avoid interfering with `required`
          (value.length <= 50 && value.split('@')[1]?.length <= 30)
      )
      .required('Email is Required'),


    business_mobileNo: Yup.string()
      .matches(/^[6-9]\d{9}$/, 'Invalid and must be exactly 10 digits')
      .notOneOf([/^(.)\1{9}$/], 'Phone number is invalid')
      .required("Phone Number is required"),


    businessAddress: Yup.string().required("Business Address is required"),
    businessPinCode: Yup.string()
      .matches(/^\d{6}$/, "Business PinCode must be 6 digits")
      .required("Business PinCode is required"),
    businessState: Yup.string().required("Business State is required"),
    businessCity: Yup.string().required("Business City is required"),
    aboutUs: Yup.string().required("About Us is required"),
    category: Yup.string().required("Category is required"),
  });


  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const data = new FormData();

        const formatTo12Hour = (time) => {
          const [hours, minutes] = time.split(":");
          let formattedHours = parseInt(hours, 10);
          const suffix = formattedHours >= 12 ? "PM" : "AM";

          if (formattedHours > 12) formattedHours -= 12;
          if (formattedHours === 0) formattedHours = 12;

          return `${formattedHours}:${minutes} ${suffix}`;
        };

        const updatedFormData = {
          ...values,
          workingHours: `${formatTo12Hour(values.workingHours.from)} to ${formatTo12Hour(values.workingHours.to)}`,
        };

        Object.keys(updatedFormData).forEach((key) => {
          if (key === "shopImage") {
            updatedFormData.shopImage.forEach((file) =>
              data.append("shopImage", file)
            );
          } else {
            data.append(key, updatedFormData[key]);
          }
        });

        const response = await UpdateProfile(data);
        if (response.data && response.data.photo) {
          setInitialValues((prevFormData) => ({
            ...prevFormData,
            image: response.data.photo,
          }));
          onImageChange(response.data.photo);
        }
        onProfileUpdate();
        navigate("/MyProfile#bank-details");
      } catch (error) {
        console.error("Error updating profile:", error);
      }
    },
  });

  useEffect(() => {
    if (profileData) {


      const workingHoursArray = profileData?.businessDetils?.workingHours
        ? profileData.businessDetils.workingHours.split(" to ").map(convertTo24Hour)
        : ["", ""];


      setInitialValues({
        shopImage: profileData?.businessDetils?.shopImage || [],
        shopName: profileData?.businessDetils?.shopName || "",
        brandImage: profileData?.businessDetils?.brandImage || null,
        gstNumber: profileData?.businessDetils?.gstNumber || "",
        workingHours: {
          from: workingHoursArray[0],
          to: workingHoursArray[1],
        },
        business_email: profileData?.businessDetils?.business_email || "",
        business_mobileNo: profileData?.businessDetils?.business_mobileNo || "",
        category: profileData?.businessDetils?.category || "",
        businessAddress: profileData?.businessDetils?.businessAddress || "",
        businessPinCode: profileData?.businessDetils?.businessPinCode || "",
        businessCity: profileData?.businessDetils?.businessCity || "",
        businessState: profileData?.businessDetils?.businessState || "",
        aboutUs: profileData?.businessDetils?.aboutUs || "",
      });
      console.log(workingHoursArray, "workingHoursArray")

      setBrandFileName(profileData?.businessDetils?.brandImage);
      setFileName(profileData?.businessDetils?.shopImage);
    }
  }, [profileData]);



  const handleFileChange = (event) => {
    const files = event?.target?.files; // FileList object
    const fileArray = Array.from(files); // Convert FileList to Array
    const fileNames = fileArray?.map((file) => file?.name); // Extract file names
    console.log(fileNames); // Log file names array
    if (fileArray?.length > 5) {
      formik.setFieldError(
        "shopImage",
        "You can only upload a maximum of 5 files"
      );
      return;
    }
    formik.setFieldValue("shopImage", fileArray);
    setFileName(fileArray.map((file) => file?.name));
  };


  const handleBrandImageChange = (e) => {
    const { files } = e.target;

    if (files && files.length > 0) {
      const selectedFile = files[0]; // Get the first selected file
      setBrandFileName(selectedFile.name); // Set the name of the selected file
      formik.setFieldValue("brandImage", selectedFile);
    }
  };


  const inputFields = [

    {
      name: "shopName",
      type: "text",
      label: "Shop Name",
      placeholder: "Shop Name",
    },
    { name: "category", type: "select", label: "Category" },

    {
      name: "gstNumber",
      type: "text",
      label: "GST Number",
      placeholder: "GST Number",
    },

    {
      name: "business_email",
      type: "email",
      label: "Business Email",
      placeholder: "Business Email",
    },
    {
      name: "business_mobileNo",
      type: "text",
      label: "Business Phone Number",
      placeholder: "Phone Number",
    },
    {
      name: "businessAddress",
      type: "text",
      label: "Business Address",
      placeholder: "business Address",
    },
    {
      name: "businessPinCode",
      type: "tel",
      label: "Business Pin Code",
      placeholder: "business PinCode",
    },

    {
      name: "aboutUs",
      type: "text",
      label: "About-Us",
      placeholder: "AboutUs",
    },
  ];


  const handleStateChange = (event) => {
    const stateName = event.target.value;
    const selectedState = states.find((state) => state.name === stateName);

    console.log(selectedState, "selectedState");

    if (selectedState) {
      // Set businessState to state name
      formik.setFieldValue("businessState", selectedState.name);

      // Fetch cities for the selected state using isoCode
      const updatedCities = City.getCitiesOfState("IN", selectedState.isoCode);
      setCities(updatedCities);

      // Reset city field
      formik.setFieldValue("businessCity", "");
    }
  };

  // Handle City Change
  const handleCityChange = (event) => {
    formik.setFieldValue("businessCity", event.target.value);
    console.log("Selected City:", event.target.value);
  };



  const handleCancel = () => {
    setInitialValues({
      shopImage: [{}],
      shopName: "",
      brandImage: '',
      gstNumber: "",
      workingHours: { from: "", to: "" },
      brandImage: "",
      business_email: "",
      business_mobileNo: "",
      category: "",
      businessAddress: "",
      businessPinCode: "",
      businessCity: "",
      businessState: "",
      aboutUs: "",
    });

  };


  console.log(formik.errors, "errors")
  return (
    <div className="pt-8">
      <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-7">
          <div>
            <label
              htmlFor="shopImage"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
            >
              Shop Image
            </label>

            <div className="w-full">

              <input
                type="file"
                name="shopImage"
                id="shopImage"
                className="hidden"
                multiple
                onChange={handleFileChange}
              />
              <label
                htmlFor="shopImage"
                className="bg-gray-50 border  border-gray-300 text-gray-900 text-sm rounded-lg p-2.5   w-full cursor-pointer flex items-center justify-between"
              >
                <span className="flex items-center">
                  {fileName.length > 0 ? fileName.join(", ") : "Choose file"}
                </span>

                <FaFileUpload className="mr-2" />
              </label>
              {formik.touched.shopImage && formik.errors.shopImage && (
                <div className="error text-red-500 text-sm">{formik.errors.shopImage}</div>
              )}

            </div>

          </div>

          <div>
            <label
              htmlFor="brandImage"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
            >
              Brand Image
            </label>

            <div className="w-full">

              <input
                type="file"
                name="brandImage"
                id="brandImage"
                className="hidden"
                onChange={handleBrandImageChange}
                accept="image/*"
              />
              <label
                htmlFor="brandImage"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 w-full cursor-pointer flex items-center justify-between"
              >
                <span className="flex items-center">
                  {brandFileName || "Choose file"}
                </span>
                <FaFileUpload className="mr-2" />
              </label>
              {formik.touched.brandImage && formik.errors.brandImage && (
                <div className="error text-red-500 text-sm">{formik.errors.brandImage}</div>
              )}

            </div>
          </div>


          <div>
            <label
              htmlFor="shopImage"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
            >
              View Shop Hour
            </label>
            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5  cursor-pointer flex items-center  ">
              <input
                type="time"

                name="workingHours.from"
                value={formik.values.workingHours.from}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <input
                type="time"
                name="workingHours.to"
                value={formik.values.workingHours.to}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />


            </div>
            {formik.touched.workingHours && formik.errors.workingHours && (
              <div className="error text-red-500 text-sm">
                {formik.errors.workingHours.from || formik.errors.workingHours.to}
              </div>
            )}


          </div>


          {inputFields.map((field) => (
            <div key={field.name} className="mb-4">
              <label
                htmlFor={field.name}
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
              >
                {field.label}
              </label>

              {field.type === "select" ? (
                <>
                  <select
                    name={field.name}
                    id={field.name}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                    value={formik.values[field.name]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" disabled>
                      Select Category
                    </option>
                    {categories.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.title}
                      </option>
                    ))}
                  </select>
                  {formik.touched[field.name] && formik.errors[field.name] && (
                    <div className="error text-red-500 text-sm">
                      {formik.errors[field.name]}
                    </div>
                  )}
                </>
              ) : (
                <input
                  type={field.type}
                  name={field.name}
                  id={field.name}
                  value={formik.values[field.name]}
                  // onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    if (field.name === 'business_mobileNo' || field.name === 'businessPinCode') {
                      // Allow only numeric input
                      const value = e.target.value.replace(/[^0-9]/g, '');
                      formik.setFieldValue(field.name, value);
                    } else {
                      formik.handleChange(e);
                    }
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                />
              )}
              {field.type !== "select" &&
                formik.touched[field.name] &&
                formik.errors[field.name] && (
                  <div className="error text-red-500 text-sm">
                    {formik.errors[field.name]}
                  </div>
                )}
            </div>
          ))}
          <div>
            <label
              htmlFor="businessState"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
            >
              Business  State
            </label>
            <select
              id="businessState"
              name="businessState"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
              value={formik.values.businessState}
              onChange={handleStateChange}
              onBlur={formik.handleBlur}
            >
              <option value="">Select State</option>
              {states.map((state) => (
                <option key={state} value={state.name}>
                  {state.name}
                </option>
              ))}
            </select>
            {formik.touched.businessState && formik.errors.businessState && (
              <div className="text-red-500 text-sm">{formik.errors.businessState}</div>
            )}
          </div>

          {/* City Dropdown */}
          <div>
            <label
              htmlFor="businessCity"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
            >
              Business  City
            </label>
            <select
              id="businessCity"
              name="businessCity"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
              value={formik.values.businessCity}
              onChange={handleCityChange}
              onBlur={formik.handleBlur}
              defaultValue={initialValues.businessCity}
            >
              <option value="">{initialValues.businessCity}</option>
              {cities.map((city, i) => (
                <option key={i} value={city.name}>
                  {city.name}
                </option>
              ))}
            </select>
            {formik.touched.businessCity && formik.errors.businessCity && (
              <div className="text-red-500 text-sm">{formik.errors.businessCity}</div>
            )}
          </div>


        </div>


        <div className="xl:flex lg:flex md:flex  flex sm:flex justify-end gap-4 pt-5 xl:pt-0 lg:pt-0">
          <Button
            type="button"
            className="border-custom-red border hover:border-custom-red w-full xl:mt-0 lg:mt-0  hover:text-white text-custom-red font-bold py-2 px-4 rounded-lg hover:bg-custom-red transition duration-300"
            text="Cancel"
            onClick={handleCancel}
          />
          <Button
            type="submit"
            className="bg-custom-red w-full border text-white font-bold py-2 px-4 rounded-lg hover:text-custom-red hover:bg-transparent hover:border-custom-red hover:border transition duration-300"
            text="Save Changes"
          />
        </div>

      </form>
    </div>
  );
};

export default MyBusiness;











