
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../Common/Button";
import useNetworkServices from "../../Networking/baseAPIManager";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../Components/Common/Toastify";
import { FaFileUpload } from "react-icons/fa";

const DocumentKyc = ({ onDataUpdate, kycData, handlePrevious, currentStep, handleNext }) => {
    const { UpdateProfile } = useNetworkServices();
    const navigate = useNavigate();
    const [panCard, setPanCard] = useState('');

    const [adhar, setAdhar] = useState('')

    // Validation schema
    const validationSchema = Yup.object().shape({
        aadhar: Yup.mixed()
            .required("Aadhar Card is required")
            .test(
                "fileType",
                "Invalid file type. Only PDF, JPEG, or PNG are allowed",
                (value) =>
                    !value || ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
            ),
        panCard: Yup.mixed()
            .required("Pan Card is required")
            .test(
                "fileType",
                "Invalid file type. Only PDF, JPEG, or PNG are allowed",
                (value) =>
                    !value || ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
            ),
    });

    // Formik setup
    const formik = useFormik({
        initialValues: {
            aadhar: kycData?.aadhar || null,
            panCard: kycData?.panCard || null,
        },
        validationSchema,
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append("aadhar", values.aadhar);
            formData.append("panCard", values.panCard);
            showToast("Kyc Complete successful!", "success");
            try {
                await UpdateProfile(formData);
                handleNext();
                const nextStep = currentStep + 1;
                localStorage.setItem("currentStep", nextStep);
                await onDataUpdate();
                setTimeout(() => navigate("/MyProfile"), 2000);
            } catch (error) {
                const apiMessage =
                    error.response?.data?.message || "Something went wrong. Please try again.";
                showToast(apiMessage, "error");
            }
        },
    });

    useEffect(() => {
        if (kycData) {
            formik.setValues({
                aadhar: kycData?.aadhar || null,
                panCard: kycData?.panCard || null,
            });
            setAdhar(kycData.aadhar || '');
            setPanCard(kycData.panCard || '');
        }
    }, [kycData]);


    const handleAdharChange = (e) => {
        const file = e.target.files[0];
        formik.setFieldValue("aadhar", file);
        setAdhar(file?.name || ''); // Corrected usage
    };

    const handlePanCardChange = (e) => {
        const file = e.target.files[0];
        formik.setFieldValue("panCard", file);
        setPanCard(file?.name || ''); // Corrected usage
    };



    return (
        <div className="pt-8">

            <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
                {/* Aadhar Input */}

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-7">
                    <div className="mb-6">
                        <label
                            htmlFor="aadhar"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
                        >
                            Aadhar Card
                        </label>
                        <div className="relative w-full">
                            <input
                                type="file"
                                id="aadhar"
                                className="hidden"
                                onChange={handleAdharChange}
                            />
                            <label
                                htmlFor="aadhar"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 block w-full cursor-pointer flex items-center justify-between"
                            >
                                <span className="flex items-center">
                                    {adhar || "Choose file"}
                                </span>
                                <FaFileUpload className="mr-2" />
                            </label>
                        </div>
                        {formik.errors.aadhar && formik.touched.aadhar && (
                            <div className="text-red-500 text-sm">{formik.errors.aadhar}</div>
                        )}
                    </div>

                    {/* Pan Card Input */}
                    <div className="mb-6">
                        <label
                            htmlFor="panCard"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
                        >
                            Pan Card
                        </label>
                        <div className="relative w-full">
                            <input
                                type="file"
                                id="panCard"
                                className="hidden"
                                onChange={handlePanCardChange}
                            />
                            <label
                                htmlFor="panCard"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 block w-full cursor-pointer flex items-center justify-between"
                            >
                                <span className="flex items-center">
                                    {panCard || "Choose file"}
                                </span>
                                <FaFileUpload className="mr-2" />
                            </label>
                        </div>
                        {formik.errors.panCard && formik.touched.panCard && (
                            <div className="text-red-500 text-sm">{formik.errors.panCard}</div>
                        )}
                    </div>
                </div>

                {/* Navigation Buttons */}
                <div className="xl:flex lg:flex md:flex sm:flex justify-between gap-4 pt-5">
                    <Button
                        type="button"
                        text="Previous"
                        className="border-custom-red border hover:border-custom-red w-full hover:text-white text-custom-red font-bold py-2 px-4 rounded-lg hover:bg-custom-red transition duration-300"
                        onClick={handlePrevious}
                        disabled={currentStep === 1}
                    />
                    <Button
                        type="submit"
                        text="Next"
                        className="bg-custom-red w-full border text-white font-bold py-2 px-4 rounded-lg hover:text-custom-red hover:bg-transparent hover:border-custom-red hover:border transition duration-300"
                    />
                </div>
            </form>
        </div>
    );
};

export default DocumentKyc;
