import React from "react";

const CategoryCard = ({ category, onCategoryClick,  activeCategory, BASE_URL }) => {
 
  if (!category) {
    return null;  
  }
 
  return (
    
    <div
      className={`text-center cursor-pointer ${activeCategory === category._id ? "text-custom-blue" : "text-gray-900"
        }`}
      onClick={() => onCategoryClick(category._id)}
    >
      <div
        className={`flex m-auto items-center justify-center bg-gray-100 rounded-full p-2 w-32 h-32 ${activeCategory === category._id
            ? "shadow-[0_4px_15px_rgba(0,0,0,0.3)] border-[1px] border-custom-blue"
            : "shadow-lg"
          }`}
      >
        <img
          src={`${BASE_URL}/${category.image}`}
          alt={category.title || "Category"}
          className="rounded-full w-full h-full object-cover"
        />
      </div>
      <h6 className="pt-4">{category.title}</h6>
    </div>
    
  );
};

export default CategoryCard;

 