import React, { useContext, useEffect, useState } from "react";
import {
  Disclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { BellIcon } from "@heroicons/react/24/outline";
import { Link, Navigate, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo/LOGO.svg";
import { ClearLocalData } from "../Networking/localStorageHelper";
import UserDefaultImage from "../assets/images/UserDefultImage.png";
import { ProfileContext } from "./ProfileContext/ProfileContext";

const Header = ({ profileImage }) => {

  const BASE_URL = "https://liveuat.com:5438";
  const [isSticky, setIsSticky] = useState(false);
  const [updateProfile, setUpdateProfile] = useState({
    userName: ""
  })
  const navigate = useNavigate();
  const { profileValue } = useContext(ProfileContext);
  const [windowConfirmstatus, setwindowConfirmstatus] = useState(false)
  const localimage = localStorage.getItem("profileImage");
  const localName = localStorage.getItem("profileName");
  // const userName = localName || "Guest";


  useEffect(() => {
    setUpdateProfile((prev) => ({ ...prev, userName: localName }))
  }, [localName])
  const userImage = localimage
    ? typeof localimage === "object"
      ? URL.createObjectURL(localimage)
      : `${BASE_URL}/${localimage}`
    : UserDefaultImage;

  const handleLogout = () => {
    let windowConfirm = window.confirm("Are you sure you want to log out");
    setwindowConfirmstatus(windowConfirm)
    if (!windowConfirm) {
      return;
    }
    ClearLocalData("");
    window.location.href = "/signin";
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

  }, []);
  console.log(profileValue, 'profileValuefgfdcxvcxv', updateProfile)
  return (
    <>
      <Disclosure as="nav" className={`bg-white shadow-md  ${isSticky ? "sticky top-0 z-50" : ""}`}>
        <div className="mx-auto max-w-8xl px-2 sm:px-6 lg:px-12">
          <div className="relative flex h-[4.5rem]  items-center justify-between">

            <div className="flex flex-1 items-center   sm:justify-start">
              <div className="flex flex-shrink-0 items-center">
                <Link to="/">
                  <img src={logo} alt="Logo" className="w-[170px]" />
                </Link>
              </div>

            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <div className="xl:pe-4  lg:pe-4 md:pe-4 sm:pe-2">
                <BellIcon aria-hidden="true" className="h-6 w-6" />
              </div>

              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-3">
                <div>
                  <MenuButton className="relative  rounded-full  flex align-center text-sm    items-center  ">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    <img alt="Profile" src={profileImage ? `${BASE_URL}/${localimage}` : userImage} className="h-8 w-8 rounded-full" />
                    {/* <h5 className="pt-1.5 ps-3">{userName}</h5> */}
                    <div className="xl:block lg:block md:block sm:block px-4 py-2 text-sm text-gray-700  hidden">
                      <h5 className="pt-1.5 ps-3">{updateProfile.userName ? updateProfile.userName : profileValue.profileName}</h5>
                    </div>
                  </MenuButton>
                </div>
                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <MenuItem>
                    <Link to="/myprofile" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                      Your Profile
                    </Link>
                  </MenuItem>

                  <MenuItem onClick={handleLogout}>
                    <Link className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">Sign out</Link>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          </div>
        </div>


      </Disclosure>
    </>
  );
};

export default Header;
