import React from "react";
import { Link } from "react-router-dom";
import Button from "../../Common/Button";
const CashFuseCard = ({ shopName, offerAmount, backgroundImage, offerName, link, buttonText, state, buttonClass }) => {
  return (
    <div
      className="bg-cover rounded-lg p-6 text-white font-sans relative w-full h-full flex flex-col justify-between"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {/* Shop Name - Positioned top right */}
      <div className="absolute top-3 right-3 text-md font-bold   bg-opacity-50 p-1 rounded">{shopName}</div>

      <div className="flex items-center justify-between">
        <div className="ps-3">
          {/* Flat Text */}
          <div className="text-[12px] xl:text-[20px] font-bold">FLAT</div>

          {/* Offer Amount */}
          <div className="text-[40px] xl:text-[50px] font-bold">{offerAmount}</div>

          {/* Offer Text */}
          <div className="text-[12px] xl:text-[20px] mb-4">FushFuse Off</div>
        </div>

        
        <div className="  offer_name  absolute top-[45%]  left-[50%]" >
          <h2 className="bg-white leading-tight text-black px-3 py-1 rounded text-sm  text-center items-center inline-block ">{offerName}</h2>
        </div>
      </div>

      
      <Link to={link} state={state}>
        <Button
          text={buttonText}
          className={buttonClass}  // Pass buttonClass to the Button component
        />
      </Link>

    </div>
  );
};

export default CashFuseCard;
