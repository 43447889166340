import React, { useState, useEffect } from 'react'
import { FaStar } from "react-icons/fa6";
import { IoLocationOutline } from "react-icons/io5";
import CashFuseCard from '../../Common/Cards/CahsFuseCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import OfferImg1 from '../../../assets/images/home/offer1.png';
import OfferImg2 from '../../../assets/images/home/offer22.png';
import OfferImg3 from '../../../assets/images/home/offer33.png';
import { useLocation } from 'react-router-dom';
import { FaPhoneAlt } from "react-icons/fa";
import AllReviews from './AllReview';
import useNetworkServices from '../../../Networking/baseAPIManager';
const ShopDetail = () => {
    const location = useLocation();
    const BASE_URL = "https://liveuat.com:5438";
    const { GetMerchantDetails } = useNetworkServices();

    const [merchantData, setMerchantData] = useState({});



    const { MerId } = location.state || {};



    useEffect(() => {
        if (MerId) {
            const fetchLocationAndData = async () => {
                navigator.geolocation.getCurrentPosition(
                    async () => {

                        const params = {
                            id: MerId
                        };

                        try {
                            const response = await GetMerchantDetails({ params });


                            setMerchantData(response.data?.data)
                            // if (response && MerId) {
                            //     const merchant = response.data?.data?.find(
                            //         merchant => merchant._id === MerId
                            //     );

                            //     if (merchant) {
                            //         console.log("Fetched merchant data:", merchant);
                            //         setMerchantData(merchant);
                            //     } else {
                            //         console.warn("No merchant found for the given MerId:", MerId);
                            //     }
                            // }


                        } catch (error) {
                            console.error("Error fetching merchant data:", error);
                        }
                    },
                    (error) => {
                        console.error("Error getting location:", error);
                    }
                );
            };

            fetchLocationAndData();
        } else {
            console.log("CategoryId is not available");
        }
    }, [MerId, GetMerchantDetails]);



    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const offerImages = [OfferImg1, OfferImg2, OfferImg3];

    if (!merchantData || merchantData.length === 0) {
        return <div>No merchant data available</div>;
    }

    const merchantDetails = merchantData[0];

    const shopAddress = merchantDetails?.address || "Address not available";
    const workingHour = merchantDetails?.businessDetils?.workingHours || "  not available";
    const shopRating = (merchantDetails?.totalRatings || merchantDetails?.totalRatings === 0) ? merchantDetails?.totalRatings : "Rating not available";
    const PhNumber = merchantDetails?.businessDetils?.business_mobileNo || "Address not available";
    const MerchantTerms = merchantData[0]?.terms[0]?.description || "Terms And Condition not Available";
    const MerchantAboutUs = merchantData[0]?.businessDetils?.aboutUs || "About Us not Available";
    const shopImages = merchantData[0]?.businessDetils?.shopImage || [];

    const offers = merchantData[0]?.offers || [];


    const shopName = merchantData[0]?.businessDetils?.shopName || "Shop Name ";


    const OffersTerms = merchantData[0]?.offers[0]?.termsAndConditions || "Terms And Condition not Available";
    const OffersAboutUs = merchantData[0]?.offers[0]?.offerDescription || "About Us not Available";

    const RatingUserName = merchantData[0]?.rating[0]?.userId?.fullName || "";
    const RatingComment = merchantData[0]?.rating[0]?.comment || "";
    const Rating = merchantData[0]?.rating[0]?.rating || "Not Available";

    const starColor = shopRating > 0 ? "text-yellow-500" : "text-gray-400";
    console.log(MerchantTerms, "MerchantTerms");


    return (
        <>

            <section className='py-20'>
                <div className='container m-auto'>
                    <div className="grid grid-cols-12  ">
                        <div className='col-span-12 '>
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                                {shopImages && shopImages.length > 0 ? (
                                    shopImages.map((image, index) => (
                                        <img
                                            key={index}
                                            src={`${BASE_URL}/${image}`}
                                            alt={`Shop Image ${index + 1}`}
                                            style={{ width: "100%", height: "100%", objectFit: 'cover' }}
                                        />
                                    ))
                                ) : (
                                    <p>No images available.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <section className='mb-10 container m-auto   bg-white shadow-md rounded-xl'>
                <div className='p-4'>
                    <div className=" ">
                        <div className="grid grid-cols-12 gap-x-4">
                            <div class="col-span-12 xl:flex lg:flex md:flex sm:flex justify-between">
                                <div className="col-span-6">
                                    <h2 className='font-bold text-custom-blue lg:text-[40px] text-[35px] md:text-[45px]  animate-fadeInLeft opacity-100'

                                        style={{ animationDelay: '0s' }}> {shopName}</h2>

                                    <p className='text-gray-400 text-lg pt-2'><span className='text-gray-600'>Address:-</span> {shopAddress}  </p>

                                </div>
                                <div className="col-span-6">
                                    <span className={`text-gray-400 text-[12px] flex items-center gap-1 ${starColor}`}><FaStar className='me-1' /> {shopRating} </span>
                                    <p className="mt-2   text-gray-400 text-[20px]">
                                        Shop  Time
                                        <span className="text-[#E73921] font-semibold"> {workingHour} </span>
                                    </p>
                                    <div className='flex gap-3 pt-3'>

                                        <span className='flex items-center text-sm text-gray-400 border-[.5px] border-gray p-1'><IoLocationOutline className='text-custom-blue me-2' /> 2.5 km</span>
                                        <span className='flex items-center text-sm text-gray-400 border-[.5px] border-gray p-1'><FaPhoneAlt className='text-custom-blue me-2 ' />  {PhNumber}</span>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="  py-10">
                        <div className="grid grid-cols-12 gap-3">
                            <div className="xl:col-span-6 col-span-12 lg:col-span-6 md:col-span-6">
                                <div>
                                    <h2 className='  text-gray-700 lg:text-[26px] text-[25px] md:text-[25px]'>About Us </h2>
                                    <p className='text-gray-400 text-[14px] pt-2'>{MerchantAboutUs ? MerchantAboutUs : ""}</p>
                                </div>


                            </div>

                            <div className="xl:col-span-6 col-span-12 lg:col-span-6 md:col-span-6">
                                <div className=' '>
                                    <h2 className='  text-gray-700 lg:text-[26px] text-[25px] md:text-[25px]'>Terms & Conditions  </h2>
                                    <p className='text-gray-400 text-[14px] pt-2'>{MerchantTerms ? MerchantTerms : "terms and condition"}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>



            <section >
                <div className="container  mx-auto">

                    <div className={`col-span-12 lg:col-span-4  ${merchantData[0]?.rating && merchantData[0].rating.length > 0 ? "h-64 overflow-x-auto" : ""
                        }`}
                    >

                        {merchantData[0]?.rating && merchantData[0].rating.length > 0 ? (
                            <>

                                <h3 className="font-bold text-lg mb-4">Cashfuse Review</h3>

                                {merchantData[0].rating.map((review, index) => (
                                    <div key={index} className="mb-4">
                                        <p className="font-medium">
                                            {index + 1}. {review?.userId?.fullName || "No User Name"}
                                        </p>
                                        <AllReviews 
                                            content={review?.comment || "No comment provided"}
                                            rating={review?.rating || "Not Available"}
                                        />
                                    </div>
                                ))}
                            </>
                        ) : (
                            <p>No reviews available</p>
                        )}
                    </div>

                </div>
            </section>


            <div className="container m-auto xl:gap-6 lg:gap-6 items-center py-10">
                {offers.length === 1 ? (
                    <div className="p-2 w-[40%]">
                        <CashFuseCard
                            shopName={shopName}
                            offerName={offers[0]?.offerTitle || "Offer Title"}
                            offerAmount={offers[0]?.storeOffer || "Offer Amount"}
                            backgroundImage={offerImages[0]}
                        />
                    </div>
                ) : offers.length > 1 ? (
                    <Slider {...settings}>
                        {offers.map((merchant, index) => (
                            <div key={merchant.merchantId?._id || index} className="p-2">
                                <CashFuseCard
                                    shopName={shopName}
                                    offerName={merchant?.offerTitle || "Offer Title"}
                                    offerAmount={merchant?.storeOffer || "Offer Amount"}
                                    backgroundImage={offerImages[index % offerImages.length]}
                                />
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <p>No offers available at the moment.</p>
                )}

            </div>



            <section>
                <div className="container m-auto pb-20 ">
                    <div className="grid grid-cols-12 gap-3">
                        <div className="xl:col-span-6 col-span-12 lg:col-span-6 md:col-span-6">
                            <div>
                                <h2 className='  text-gray-700 lg:text-[26px] text-[25px] md:text-[25px]'>About Us </h2>
                                <p className='text-gray-400 text-[14px] pt-2'>{OffersAboutUs ? OffersAboutUs : ""}</p>
                            </div>


                        </div>

                        <div className="xl:col-span-6 col-span-12 lg:col-span-6 md:col-span-6">
                            <div className=' '>
                                <h2 className='  text-gray-700 lg:text-[26px] text-[25px] md:text-[25px]'>Terms & Conditions  </h2>
                                <p className='text-gray-400 text-[14px] pt-2'> {OffersTerms ? OffersTerms : "Terms And Condition"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ShopDetail;
