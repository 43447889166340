import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useNetworkServices from "../../Networking/baseAPIManager";
 
const RedeemModal = ({ onClose, fetchTransactions }) => {
  const { WithDrawAmonut, GetProfile} = useNetworkServices(); 
  const formik = useFormik({
    initialValues: {
      withdrawAmount: "",
      convertCash: false,
      reward_points: "",
      accountHolder: "",
      accountNo: "",
      bankName: "",
      ifscCode: "",
    },
    validationSchema: Yup.object({
      withdrawAmount: Yup.number()
        .required("Withdrawal amount is required")
        .positive("Amount must be positive")
        .integer("Amount must be a whole number"),
      convertCash: Yup.boolean(),
    }),
    onSubmit: async (values) => {
      const { withdrawAmount, convertCash, reward_points, accountHolder, accountNo, bankName, ifscCode } = values;

      const withdrawalData = {
        convertCash,
        reward_points: +withdrawAmount,
        accountHolder,
        accountNo,
        bankName,
        ifscCode,
      };

      console.log("Final withdrawal data before sending:", withdrawalData);

      try {
        const response = await WithDrawAmonut(withdrawalData);
        console.log("Withdrawal successful:", response);
        onClose();
        await fetchTransactions();
      } catch (error) {
        if (error.response) {
          console.error("Error processing withdrawal:", error.response.data);
        } else {
          console.error("Error processing withdrawal:", error.message);
        }
      }
    },
  });
 
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const profileResponse = await GetProfile();
        const profile = profileResponse.data.data;

        formik.setValues((prevValues) => ({
          ...prevValues,
          reward_points: profile.reward_points || "",
          accountHolder: profile.bankDetails?.accountHolderName || "",
          accountNo: profile.bankDetails?.accountNumber || "",
          bankName: profile.bankDetails?.bankName || "",
          ifscCode: profile.bankDetails?.ifscCode || "",
        }));
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchProfileData();
  }, [GetProfile]);

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-bold mb-4 text-center">Confirm Withdraw</h2>

        <form onSubmit={formik.handleSubmit}>
          {/* Withdrawal Amount Input */}
          <div className="mb-4">
            <label className="block text-gray-700">Withdrawal CashFuse</label>
            <input
              type="text"
              name="withdrawAmount"
              value={formik.values.withdrawAmount}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, "");
                formik.setFieldValue("withdrawAmount", value);
              }}
              onBlur={formik.handleBlur}
              className={`w-full px-4 py-2 border rounded mt-2 ${formik.touched.withdrawAmount && formik.errors.withdrawAmount
                ? "border-red-500"
                : ""
                }`}
              placeholder="Enter withdrawal amount"
            />
            {formik.touched.withdrawAmount && formik.errors.withdrawAmount && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.withdrawAmount}</div>
            )}
          </div>

          {/* Checkbox for converting cash */}
          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              name="convertCash"
              checked={formik.values.convertCash}
              onChange={formik.handleChange}
              className="mr-2"
            />
            <label className="text-gray-700">Do you want to convert to cash?</label>
          </div>

          <div className="flex justify-between">
            <button
              type="button"
              className="px-4 py-2 border rounded text-red-500 border-red-500 hover:bg-red-100"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            >
              Withdraw
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RedeemModal;
