import React, { useState } from "react";
import { useFormik } from "formik";
import BackgroundImage from "../../assets/images/img/SIGNUP-BG.png";
import LoginImg from "../../assets/images/img/login.png";
import Button from "../Common/Button";
import { NavLink, useNavigate } from "react-router-dom";
import useNetworkServices from "../../Networking/baseAPIManager";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Location from "./Location";
import { HeaderLogo } from "./HeaderLogo";
import { showToast } from "../Common/Toastify";

const SignUp = () => {
  const { signUpMerchant } = useNetworkServices();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      email: "",
      mobile: "",
      password: "",
      type: "merchant",
      location: {
        type: "Point",
        coordinates: [],
      },
      field_executive: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.firstName.trim()) {
        errors.firstName = "First Name is required";
      }
      else if (!/^[A-Za-z]+$/.test(values.firstName)) {
        errors.firstName = "First Name can only contain alphabetical characters";
      } else if (values.firstName.length > 15) {
        errors.firstName = "First Name cannot exceed 15 characters";
      }


      // if (!values.email.trim()) {
      //   errors.email = "Email is required";
      // } else if (!/^[a-zA-Z0-9._%+-]{5,20}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(values.email) ) {
      //   errors.email = 'Email must have a min 5 and max 20 alphanumeric characters and must not contain text after the domain (e.g., ".com")'
      // }  else if (
      //   /\.\w{2,}\.\w{2,}$/.test(values.email)
      // ) {
      //   errors.email = "Email must not have a double domain (e.g., '.com.us')";
      // }

   
      if (!values.email.trim()) {
        errors.email = "Email is required";
      } else if (!/^[a-zA-Z0-9._%+-]{5,20}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(values.email)) {
        errors.email =
          "Email must have 5-20 alphanumeric characters before '@' and a valid domain (e.g., '.com')";
      } else if (/\.\w{2,3}\.\w{2,3}$/.test(values.email)) {
        errors.email = "Email must not have a double domain (e.g., '.com.com')";
      } else if (
        values.email.length > 50 || // Limit total email length to 50 characters
        values.email.split("@")[1]?.length > 30 // Limit the domain part after @ to 30 characters
      ) {
        errors.email = "Email is too long or has an excessively long domain.";
      }




      // Validate password
      if (!values.password.trim()) {
        errors.password = "Password is required";
      } else if (!/^[a-zA-Z0-9!@#$%^&*()_+=\-{}[\]:;'",.<>?/|~`]{6,20}$/.test(values.password)) {
        errors.password = "Password must be 6 to 20 characters long and include only alphanumeric or special characters";
      }
      else if (!/^[\x20-\x7E]+$/.test(values.password)) {
        errors.password = "Password cannot contain emojis or unsupported characters";
      }

      // Validate mobile
      if (!values.mobile.trim()) {
        errors.mobile = "Phone Number is required";
      } else if (!/^\d{10}$/.test(values.mobile)) {
        errors.mobile = "Phone Number must be exactly 10 digits";
      } else if (/^(.)\1{9}$/.test(values.mobile)) {
        errors.mobile = "Phone Number is invalid";
      }
      else if (!/^[6-9]\d{9}$/.test(values.mobile)) {
        errors.mobile = "Phone Number is invalid";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        const response = await signUpMerchant({ ...values, mobile: values.mobile });
        console.log(response, "ResponseData");
        showToast("Register successful!", "success");
        navigate("/signin");
      } catch (error) {
        const apiMessage =
          error.response?.data?.message || "Something went wrong. Please try again.";
        showToast(apiMessage, "error");
      }
    },
  });

  const handleLocationChange = (location) => {
    formik.setFieldValue("location", {
      type: "Point",
      coordinates: [location.longitude, location.latitude],
    });
  };

  return (
    <div className="bg-cover bg-center min-h-screen" style={{ backgroundImage: `url(${BackgroundImage})` }}>
      <HeaderLogo />
      <Location onLocationChange={handleLocationChange} />

      <div className="flex items-center justify-center">
        <div className="container p-8 lg:m-0 md:m-0">
          <div className="grid grid-cols-12 gap-8">
            <div className="col-span-12 md:col-span-6 lg:col-span-6 flex items-center justify-center">
              <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-white dark:border-gray-700">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-800">
                    Sign Up
                  </h1>
                  <p className="text-gray-400 text-sm">
                    Join us today! Fill in the details below to start your journey with us.
                  </p>
                  <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
                    <div>
                      <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">
                        First Name <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none focus:bg-blue-50 dark:placeholder-gray-400 dark:text-gray-800"
                        placeholder="First Name"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.firstName && formik.errors.firstName && (
                        <p className="text-red-500 text-xs mt-1">{formik.errors.firstName}</p>
                      )}
                    </div>
                    <div>
                      <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">
                        Email Id <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="bg-gray-50 border focus:bg-blue-50  border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                        placeholder="Email Id"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <p className="text-red-500 text-xs mt-1">{formik.errors.email}</p>
                      )}
                    </div>
                    <div>
                      <label htmlFor="mobile" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">
                        Mobile Number <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        name="mobile"
                        id="mobile"
                        className="bg-gray-50 border focus:bg-blue-50  border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                        placeholder="Mobile Number"
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.mobile && formik.errors.mobile && (
                        <p className="text-red-500 text-xs mt-1">{formik.errors.mobile}</p>
                      )}
                    </div>
                    <div className="relative">
                      <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">
                        Create  Password <span className="text-red-600">*</span>
                      </label>
                      <input
                        type={passwordVisible ? "text" : "password"}
                        name="password"
                        id="password"
                        className="bg-gray-50 border focus:bg-blue-50  border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                        placeholder=" Create Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <button
                        type="button"
                        className="absolute inset-y-0 right-0 top-6 flex items-center pr-3"
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      >
                        {passwordVisible ? <FaEye className="text-gray-500" /> : <FaEyeSlash className="text-gray-500" />}
                      </button>
                      {formik.touched.password && formik.errors.password && (
                        <p className="text-red-500 text-xs mt-1">{formik.errors.password}</p>
                      )}
                    </div>

                    <div>
                      <label htmlFor="field_executive" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">
                        Referral By
                      </label>
                      <input
                        type="text"
                        name="field_executive"
                        id="field_executive"
                        className="bg-gray-50 border focus:bg-blue-50  border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                        placeholder="Referral By"
                        value={formik.values.field_executive}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div>
                      <Button
                        type="submit"
                        className="bg-custom-blue w-full text-white font-bold py-2 px-4 rounded hover:bg-custom-blue2 transition duration-300"
                        text={"Submit"}
                      />
                    </div>
                    <p className="text-sm font-light">
                      <span className="text-gray-500 dark:text-gray-600">Already have an account?</span>
                      <NavLink
                        to="/signin"
                        className="font-medium text-primary-600 text-blue-400 ps-2 hover:underline dark:text-blue-500"
                      >
                        Login
                      </NavLink>
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-6 flex justify-center items-center">
              <img className="w-full  " src={LoginImg} alt="login" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
