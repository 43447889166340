import React from "react";
import { FaStar } from "react-icons/fa";

const AllReviews = ({ username, date, content, rating }) => {

    const starColor = rating > 0 ? "text-yellow-500" : "text-gray-400";
    return (




        <div
            className="border-b border-gray-200 pb-4 mb-4 last:border-none last:pb-0 last:mb-0"
        >
            {/* Reviewer Info */}
            <div className="flex items-center mb-2">

                <div className="">
                    <p className="font-medium text-gray-700">{username}</p>

                </div>
            </div>

            {/* Rating and Content */}
            <div className="flex items-center">

                <span className={`text-gray-400 text-[12px] flex items-center gap-1 ${starColor}`}><FaStar className='me-1' /> {rating} </span>

                <span className="ml-4 text-xs text-gray-500">{date}</span>
            </div>
            <p className="mt-2 text-gray-500 text-[13px]">{content}</p>


        </div>



    );
};

export default AllReviews;
