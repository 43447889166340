import React, { useEffect, useState } from "react";
import Button from "../Common/Button";
import useNetworkServices from "../../Networking/baseAPIManager";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { State, City } from 'country-state-city';
import { FaFileUpload } from "react-icons/fa"; 

const convertTo24Hour = (time) => {
  const [hoursMinutes, period] = time.split(" ");
  let [hours, minutes] = hoursMinutes.split(":").map(Number);

  if (period === "PM" && hours !== 12) {
    hours += 12;
  } else if (period === "AM" && hours === 12) {
    hours = 0;
  }

  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
};


const MyBusinessKyc = ({ onDataUpdate,kycData, handlePrevious, handleNext, currentStep }) => {
  const { UpdateProfile, Categorylist } = useNetworkServices();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [fileName, setFileName] = useState("");
  const [brandFileName, setBrandFileName] = useState("") 
  const [states] = useState(State.getStatesOfCountry('IN')); // Fetch all states of India
  const [cities, setCities] = useState([]);

  const [initialValues, setInitialValues] = useState({
    shopImage: [],
    shopName: "",
    brandImage: null,
    gstNumber: "",
    workingHours: { from: "", to: "" },
    brandImage: "",
    business_email: "",
    business_mobileNo: "",
    category: "",
    businessAddress: "",
    businessPinCode: "",
    businessCity: "",
    businessState: "",
    aboutUs: "",
  });


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await Categorylist();
        if (response?.data?.data) {
          setCategories(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const validationSchema = Yup.object({
    shopName: Yup.string().required("Shop Name is required"),
    gstNumber: Yup.string()
      .required("GST Number is required")
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "Invalid GST Format"
      ),


    shopImage: Yup.array()
      .min(1, "At least one shop image is required")
      .required("Shop image is required"),


    brandImage: Yup.mixed().required("Brand Image is required"),
    workingHours: Yup.object().shape({
      from: Yup.string().required("Start time is required"),
      to: Yup.string().required("End time is required"),
    }),


    business_email: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[a-zA-Z0-9._%+-]{5,20}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/,
        'Email must have a min 5 and max 20 alphanumeric characters and must not contain text after the domain (e.g., ".com")'
      )
      .matches(
        /^(?!.*\.\w{2,}\.\w{2,}$)/,
        'Email must not have a double domain (e.g., ".com.us")'
    )
      .test(
        'length-check',
        'Email is too long or has an excessively long domain.',
        (value) =>
          !value || // Allow empty strings to avoid interfering with `required`
          (value.length <= 50 && value.split('@')[1]?.length <= 30)
      )
      .required('Email is Required'),
    
      
    business_mobileNo: Yup.string()
      .matches(/^[6-9]\d{9}$/, 'Invalid and must be exactly 10 digits')
      .notOneOf([/^(.)\1{9}$/], 'Phone number is invalid')
      .required("Phone Number is required"),
     
      
    businessAddress: Yup.string().required("Business Address is required"),
    businessPinCode: Yup.string()
      .matches(/^\d{6}$/, "Business PinCode must be 6 digits")
      .required("Business PinCode is required"),
    businessState: Yup.string().required("Business State is required"),
    businessCity: Yup.string().required("Business City is required"),
    aboutUs: Yup.string().required("About Us is required"),
    category: Yup.string().required("Category is required"),
  });

 
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const data = new FormData();
    
        const formatTo12Hour = (time) => {
          const [hours, minutes] = time.split(":");
          let formattedHours = parseInt(hours, 10);
          const suffix = formattedHours >= 12 ? "PM" : "AM";

          if (formattedHours > 12) formattedHours -= 12;
          if (formattedHours === 0) formattedHours = 12;

          return `${formattedHours}:${minutes} ${suffix}`;
        };

        const updatedFormData = {
          ...values,
          workingHours: `${formatTo12Hour(values.workingHours.from)} to ${formatTo12Hour(values.workingHours.to)}`,
        };

        Object.keys(updatedFormData).forEach((key) => {
          if (key === "shopImage") {
            updatedFormData.shopImage.forEach((file) =>
              data.append("shopImage", file)
            );
          } else {
            data.append(key, updatedFormData[key]);
          }
        });

        const response = await UpdateProfile(data);
        if (response) {
          handleNext();
          const nextStep = currentStep + 1;
          localStorage.setItem("currentStep", nextStep);
        await  onDataUpdate();
          navigate("/kycComponents#bankdetailskyc");
        }
      } catch (error) {
        console.error("Error updating profile:", error);
      }
    },
  });

  useEffect(() => {
    if (kycData) { 

   
      const workingHoursArray = kycData?.businessDetils?.workingHours
        ? kycData.businessDetils.workingHours.split(" to ").map(convertTo24Hour)
        : ["", ""];

      
        setInitialValues({
          shopImage: kycData?.businessDetils?.shopImage || [],
          shopName: kycData?.businessDetils?.shopName || "",
          brandImage: kycData?.businessDetils?.brandImage || null,
          gstNumber: kycData?.businessDetils?.gstNumber || "", 
          workingHours: {
            from: workingHoursArray[0],
            to: workingHoursArray[1],
          },
          business_email: kycData?.businessDetils?.business_email || "",
          business_mobileNo: kycData?.businessDetils?.business_mobileNo || "",
          category: kycData?.businessDetils?.category || "",
          businessAddress: kycData?.businessDetils?.businessAddress || "",
          businessPinCode: kycData?.businessDetils?.businessPinCode || "",
          businessCity: kycData?.businessDetils?.businessCity || "",
          businessState: kycData?.businessDetils?.businessState || "",
          aboutUs: kycData?.businessDetils?.aboutUs || "",
        });
      console.log(workingHoursArray,"workingHoursArray")
     
      setBrandFileName(kycData?.businessDetils?.brandImage);
      setFileName(kycData?.businessDetils?.shopImage);
    }
  }, [kycData]);



  const handleFileChange = (event) => {
    const files = event?.target?.files; // FileList object
    const fileArray = Array.from(files); // Convert FileList to Array
    const fileNames = fileArray?.map((file) => file?.name); // Extract file names
    console.log(fileNames); // Log file names array
    if (fileArray?.length > 5) {
      formik.setFieldError(
        "shopImage",
        "You can only upload a maximum of 5 files"
      );
      return;
    }
    formik.setFieldValue("shopImage", fileArray);
    setFileName(fileArray.map((file) => file?.name));
  };


  const handleBrandImageChange = (e) => {
    const { files } = e.target;

    if (files && files.length > 0) {
      const selectedFile = files[0]; // Get the first selected file
      setBrandFileName(selectedFile.name); // Set the name of the selected file
      formik.setFieldValue("brandImage", selectedFile);
    }
  };


  const inputFields = [

    {
      name: "shopName",
      type: "text",
      label: "Shop Name",
      placeholder: "Shop Name",
    },
    { name: "category", type: "select", label: "Category" },

    {
      name: "gstNumber",
      type: "text",
      label: "GST Number",
      placeholder: "GST Number",
    },

    {
      name: "business_email",
      type: "email",
      label: "Business Email",
      placeholder: "Business Email",
    },
    {
      name: "business_mobileNo",
      type: "text",
      label: "Business Phone Number",
      placeholder: "Phone Number",
    },
    {
      name: "businessAddress",
      type: "text",
      label: "Business Address",
      placeholder: "business Address",
    },
    {
      name: "businessPinCode",
      type: "tel",
      label: "Business Pin Code",
      placeholder: "business PinCode",
    },

    {
      name: "aboutUs",
      type: "text",
      label: "About-Us",
      placeholder: "AboutUs",
    },
  ];


  const handleStateChange = (event) => {
    const stateName = event.target.value;
    const selectedState = states.find((state) => state.name === stateName);

    console.log(selectedState, "selectedState");

    if (selectedState) {
      // Set businessState to state name
      formik.setFieldValue("businessState", selectedState.name);

      // Fetch cities for the selected state using isoCode
      const updatedCities = City.getCitiesOfState("IN", selectedState.isoCode);
      setCities(updatedCities);

      // Reset city field
      formik.setFieldValue("businessCity", "");
    }
  };

  // Handle City Change
  const handleCityChange = (event) => {
    formik.setFieldValue("businessCity", event.target.value);
    console.log("Selected City:", event.target.value);
  };



    const handleCancel = () => {
      setInitialValues({
        shopImage: [{}],
        shopName: "",
        brandImage: '',
        gstNumber: "",
        workingHours: { from: "", to: "" },
        brandImage: "",
        business_email: "",
        business_mobileNo: "",
        category: "",
        businessAddress: "",
        businessPinCode: "",
        businessCity: "",
        businessState: "",
        aboutUs: "",
      });
     
    };


  console.log(formik.errors, "errors")
  return (
    <div className="pt-8">
      <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-7">
          <div>
            <label
              htmlFor="shopImage"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
            >
              Shop Image
            </label>

            <div className="w-full">

              <input
                type="file"
                name="shopImage"
                id="shopImage" 
                className="hidden"
                multiple
                onChange={handleFileChange}
              />
              <label
                htmlFor="shopImage"
                className="bg-gray-50 border  border-gray-300 text-gray-900 text-sm rounded-lg p-2.5   w-full cursor-pointer flex items-center justify-between"
              >
                <span className="flex items-center">
                  {fileName.length > 0 ? fileName.join(", ") : "Choose file"}
                </span>

                <FaFileUpload className="mr-2" />
              </label>
              {formik.touched.shopImage && formik.errors.shopImage && (
                <div className="error text-red-500 text-sm">{formik.errors.shopImage}</div>
              )}

            </div>

          </div>

          <div>
            <label
              htmlFor="brandImage"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
            >
              Brand Image
            </label>

            <div className="w-full">

              <input
                type="file"
                name="brandImage"
                id="brandImage"
                className="hidden"
                onChange={handleBrandImageChange}
                accept="image/*"
              />
              <label
                htmlFor="brandImage"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 w-full cursor-pointer flex items-center justify-between"
              >
                <span className="flex items-center">
                  {brandFileName || "Choose file"}
                </span>
                <FaFileUpload className="mr-2" />
              </label>
              {formik.touched.brandImage && formik.errors.brandImage && (
                <div className="error text-red-500 text-sm">{formik.errors.brandImage}</div>
              )}

            </div>
          </div>


          <div>
            <label
              htmlFor="shopImage"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
            >
              View Shop Hour
            </label>
            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5  cursor-pointer flex items-center  ">
              <input
                type="time"

                name="workingHours.from"
                value={formik.values.workingHours.from}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <input
                type="time"
                name="workingHours.to"
                value={formik.values.workingHours.to}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
 

            </div>
            {formik.touched.workingHours && formik.errors.workingHours && (
              <div className="error text-red-500 text-sm">
                {formik.errors.workingHours.from || formik.errors.workingHours.to}
              </div>
            )}


          </div>


          {inputFields.map((field) => (
            <div key={field.name} className="mb-4">
              <label
                htmlFor={field.name}
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
              >
                {field.label}
              </label>

              {field.type === "select" ? (
                <>
                  <select
                    name={field.name}
                    id={field.name}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                    value={formik.values[field.name]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" disabled>
                      Select Category
                    </option>
                    {categories.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.title}
                      </option>
                    ))}
                  </select>
                  {formik.touched[field.name] && formik.errors[field.name] && (
                    <div className="error text-red-500 text-sm">
                      {formik.errors[field.name]}
                    </div>
                  )}
                </>
              ) : (
                <input
                  type={field.type}
                  name={field.name}
                  id={field.name}
                  value={formik.values[field.name]}
                  // onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      if (field.name === 'business_mobileNo' || field.name === 'businessPinCode') {
                        // Allow only numeric input
                        const value = e.target.value.replace(/[^0-9]/g, '');
                        formik.setFieldValue(field.name, value);
                      } else {
                        formik.handleChange(e);
                      }
                    }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                />
              )}
              {field.type !== "select" &&
                formik.touched[field.name] &&
                formik.errors[field.name] && (
                  <div className="error text-red-500 text-sm">
                    {formik.errors[field.name]}
                  </div>
                )}
            </div>
          ))}
          <div>
            <label
              htmlFor="businessState"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
            >
            Business  State
            </label>
            <select
              id="businessState"
              name="businessState"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
              value={formik.values.businessState}
              onChange={handleStateChange}
              onBlur={formik.handleBlur}
            >
              <option value="">Select State</option>
              {states.map((state) => (
                <option key={state} value={state.name}>
                  {state.name}
                </option>
              ))}
            </select>
            {formik.touched.businessState && formik.errors.businessState && (
              <div className="text-red-500 text-sm">{formik.errors.businessState}</div>
            )}
          </div>

          {/* City Dropdown */}
          <div>
            <label
              htmlFor="businessCity"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
            >
            Business  City
            </label>
            <select
              id="businessCity"
              name="businessCity"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
              value={formik.values.businessCity}
              onChange={handleCityChange}
              onBlur={formik.handleBlur}
              defaultValue={initialValues.businessCity}
            >
              <option value="">{initialValues.businessCity}</option>
              {cities.map((city, i) => (
                <option key={i} value={city.name}>
                  {city.name}
                </option>
              ))}
            </select>
            {formik.touched.businessCity && formik.errors.businessCity && (
              <div className="text-red-500 text-sm">{formik.errors.businessCity}</div>
            )}
          </div>


        </div>


        <div className=" xl:flex lg:flex md:flex  xm:flex  items-center sm:flex justify-between gap-4 pt-5 xl:pt-0 lg:pt-0">
          <div>
            <Button
              type="button"
              text="Previous"
              className="border-custom-red border  hover:border-custom-red w-full xl:mt-0 lg:mt-0  hover:text-white text-custom-red font-bold py-2 px-4 rounded-lg hover:bg-custom-red transition duration-300"
              onClick={handlePrevious}
              disabled={currentStep === 1}
            />
          </div>
          <div className="xl:flex lg:flex md:flex  flex sm:flex gap-4 pt-5 md:pt-0 sm:pt-0 xl:pt-0 lg:pt-0">
            <Button
              type="button"
              className="border-custom-red border hover:border-custom-red w-full xl:mt-0 lg:mt-0  hover:text-white text-custom-red font-bold py-2 px-4 rounded-lg hover:bg-custom-red transition duration-300"
              text="Cancel"
            onClick={handleCancel}
            />
            <Button
              type="submit"
              className="bg-custom-red w-full border text-white font-bold py-2 px-4 rounded-lg hover:text-custom-red hover:bg-transparent hover:border-custom-red hover:border transition duration-300"
              text="Next"
              onClick={formik.handleSubmit}
            />
          </div>
        </div>

      </form>
    </div>
  );
};

export default MyBusinessKyc;










