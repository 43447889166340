import React, { useState } from "react";
import { useFormik } from "formik";
import BackgroundImage from "../../assets/images/img/SIGNUP-BG.png";
import LoginImg from "../../assets/images/img/login.png";
import Button from "../Common/Button";
import { NavLink, useNavigate } from "react-router-dom";
import useNetworkServices from "../../Networking/baseAPIManager";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { setGetLocalData } from "../../Networking/localStorageHelper";
import { HeaderLogo } from "./HeaderLogo";
import { showToast } from "../Common/Toastify";

const SignIn = () => {
  const { signInMerchant } = useNetworkServices();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = "Email is required";
      } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(values.email)) {
        errors.email = "Email address is invalid";
      } else if (/\.[a-zA-Z0-9.-]*\.[a-zA-Z]{2,6}$/.test(values.email)) {
        // This regex checks if there are multiple dots in the domain (e.g., "singhsoft.com.com")
        errors.email = "Email address cannot Valid";
      }
      if (!values.password) {
        errors.password = "Password is required";
      } else if (values.password.length < 6) {
        errors.password = "Password must be at least 6 characters long";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        const response = await signInMerchant(values);
        console.log(response.data, "ResponseData");
        const token = response.data.tokens;
        setGetLocalData("token", token);
        localStorage.setItem("userKyc", response?.data?.tokens?.isKyc);

        showToast("Login successful!", "success");
        if (response.data.isKyc) {
          navigate("/dashboard");
        } else {
          navigate("/kycComponents");
        }
      } catch (error) {
        const apiMessage =
          error.response?.data?.message || "Something went wrong. Please try again."; 
          showToast(apiMessage, "error"); 
       
      }
    },
  });

  return (
    <div
      className="bg-cover bg-center min-h-screen"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <HeaderLogo />

      <div className="flex items-center justify-center">
        <div className="container lg:m-0 md:m-0">
          <div className="grid grid-cols-12 gap-8">
            <div className="col-span-12 md:col-span-6 lg:col-span-6 flex items-center justify-center">
              <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-white dark:border-gray-700">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-800">
                    Sign In
                  </h1>
                  <p className="text-gray-400 text-sm">
                    Sign in to your account and continue where you left off.
                  </p>
                  <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
                    <div>
                      <label
                        htmlFor="email"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
                      >
                        Email <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="bg-gray-50 border focus:bg-blue-50  border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                        placeholder="Enter.."
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <p className="text-red-500 text-xs mt-1">{formik.errors.email}</p>
                      )}
                    </div>
                    <div className="relative">
                      <label
                        htmlFor="password"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
                      > 
                        Password <span className="text-red-600">*</span>
                      </label>
                      <input
                        type={passwordVisible ? "text" : "password"}
                        name="password"
                        id="password"
                        className="bg-gray-50 border focus:bg-blue-50  border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                        placeholder="Enter.."
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <button
                        type="button"
                        className="absolute inset-y-0 right-0 top-6 flex items-center pr-3"
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      >
                        {passwordVisible ? (
                        <FaEye className="text-gray-500" />
                        ) : (
                          <FaEyeSlash className="text-gray-500" />
                        )}
                      </button>
                      {formik.errors.password && formik.touched.password && (
                        <p className="text-red-500 text-xs mt-1">{formik.errors.password}</p>
                      )}
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          id="rememberMe"
                          name="rememberMe"
                          type="checkbox"
                          checked={formik.values.rememberMe}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="h-4 w-4 text-primary-600 border-gray-300 rounded focus:ring-primary-500"
                        />
                        <label
                          htmlFor="rememberMe"
                          className="ml-2 block text-sm text-gray-900 dark:text-gray-800"
                        >
                          Remember me
                        </label>
                      </div>
                      <div className="text-sm">
                        <NavLink
                          to="/forgotpassword"
                          className="font-medium text-primary-600 text-blue-400 hover:underline dark:text-blue-500"
                        >
                          Forgot your password?
                        </NavLink>
                      </div>
                    </div>
                    <div>
                      <Button
                        type="submit"
                        className="bg-custom-blue w-full text-white font-bold py-2 px-4 rounded hover:bg-custom-blue2 transition duration-300"
                        text={"Login"}
                      />
                    </div>
                    <p className="text-sm font-light">
                      <span className="text-gray-500 dark:text-gray-600">
                        Don't have an account?
                      </span>
                      <NavLink
                        to="/signup"
                        className="font-medium text-primary-600 text-blue-400 ps-2 hover:underline dark:text-blue-500"
                      >
                        Sign Up
                      </NavLink>
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-6 flex items-center justify-center">
              <img src={LoginImg} alt="Login" className="max-w-full h-auto" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
