import React, { useEffect, useState } from "react";
import Button from "../Common/Button";
import useNetworkServices from "../../Networking/baseAPIManager";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { State, City } from 'country-state-city';


const PersonalInfo = ({
  profileData,
  onImageChange,
  onProfileUpdate,
}) => {
  const { UpdateProfile } = useNetworkServices();
  const navigate = useNavigate();
  const [states] = useState(State.getStatesOfCountry('IN')); // Fetch all states of India
  const [cities, setCities] = useState([]);


  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    address: '',
    pinCode: '',
    state: '',
    city: '',
  });



  useEffect(() => {
    if (profileData) {
      setInitialValues((prevFormData) => ({
        ...prevFormData,
        firstName: profileData?.firstName || "",
        lastName: profileData?.lastName || "",
        email: profileData?.email || "",
        mobile: profileData?.mobile || "",
        address: profileData?.address || "",
        pinCode: profileData?.pinCode || "",
        city: profileData?.city || "",
        state: profileData?.state || "",
        // profileImage: profileImage,
      }));
    }
  }, [
    // profileImage,
    profileData,
  ]);




  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(/^[A-Z][a-zA-Z]*$/, 'First name must start with a capital letter and contain no spaces')
      .matches(
        /^[A-Z][a-zA-Z]*$/,
        ' First Name contain only alphabetic characters without spaces'
      )
      .max(15, 'First name cannot exceed 15 characters') // Max length validation
      .required('First Name is Required'), // Required field validation
    lastName: Yup.string()
      .matches(/^[A-Z][a-zA-Z]*$/, 'Last name must start with a capital letter and contain no spaces')
      .matches(
        /^[A-Z][a-zA-Z]*$/,
        ' Last Name contain only alphabetic characters without spaces'
      )
      .max(15, 'Last name cannot exceed 15 characters') // Max length validation
      .required('Last Name is Required'),
    email: Yup.string()
      .email('Invalid Email Address')
      .matches(
        /^[a-zA-Z0-9._%+-]{5,20}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/,
        'Email must have a min 5 and max 20 alphanumeric characters and must not contain text after the domain (e.g., ".com")'
      )
      .matches(
        /^(?!.*\.\w{2,}\.\w{2,}$)/,
        'Email must not have a double domain (e.g., ".com.us")'
      )
      .test(
        'length-check',
        'Email is too long or has an excessively long domain.',
        (value) =>
          !value || // Allow empty strings to avoid interfering with `required`
          (value.length <= 50 && value.split('@')[1]?.length <= 30)
      )
      .required('Email is Required'),

    mobile: Yup.string()
      .matches(/^[6-9]\d{9}$/, 'Invalid and must be exactly 10 digits')
      .notOneOf([/^(.)\1{9}$/], 'Phone number is invalid')
      .required('Phone Number is required'),
    address: Yup.string().required('Address is required'),
    pinCode: Yup.string()
      .matches(/^\d{6}$/, 'Pincode must be a valid 6-digit number')
      .required('Pincode is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
  });


  const formik = useFormik({
    enableReinitialize: true, // Allow dynamic reinitialization when initialValues change
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const data = new FormData();
      Object.keys(values).forEach((key) => {
        data.append(key, values[key]);
      });
      try {
        const response = await UpdateProfile(data);
        console.log(response.data, 'ResponseData');

        if (response.data && response.data.profileImage) {
          setInitialValues((prevFormData) => ({
            ...prevFormData,
            profileImage: response.data.profileImage,
          }));

          onImageChange(response.data.profileImage);
          console.log(response.data.profileImage, "Profile====>");
        }
        onProfileUpdate();
        navigate("/MyProfile#my-business");
      } catch (error) {
        console.error('Update Profile error:', error);
      }
    },
  });


  const handleStateChange = (event) => {
    const stateName = event.target.value;
    const selectedState = states.find((state) => state.name === stateName);

    console.log(selectedState, "selectedState");

    if (selectedState) {
      // Set businessState to state name
      formik.setFieldValue("state", selectedState.name);

      // Fetch cities for the selected state using isoCode
      const updatedCities = City.getCitiesOfState("IN", selectedState.isoCode);
      setCities(updatedCities);

      // Reset city field
      formik.setFieldValue("city", "");
    }
  };

  // Handle City Change
  const handleCityChange = (event) => {
    formik.setFieldValue("city", event.target.value);
    console.log("Selected City:", event.target.value);
  };

  const handleCancel = () => {
    setInitialValues({
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      address: '',
      pinCode: '',
      state: '',
      city: '',
    });

  };
  return (
    <div className="pt-8">
      <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-7">
          {[
            {
              name: "firstName",
              type: "text",
              label: "First Name",
              placeholder: "First name",
            },
            {
              name: "lastName",
              type: "text",
              label: "Last Name",
              placeholder: "Last name",
            },
            {
              name: "email",
              type: "email",
              label: "Email Id",
              placeholder: "Email Id",
            },
            {
              name: "mobile",
              type: "tel",
              label: "Phone Number",
              placeholder: "Phone number",
            },
            {
              name: "address",
              type: "text",
              label: "Address",
              placeholder: "Address",
            },
            {
              name: "pinCode",
              type: "tel",
              label: "Pincode",
              placeholder: "Pincode",
            },
            // { name: "city", type: "text", label: "City", placeholder: "City" },
            // {
            //   name: "state",
            //   type: "text",
            //   label: "State",
            //   placeholder: "State",
            // },
          ].map((field) => (
            <div key={field.name}>
              <label
                htmlFor={field.name}
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
              >
                {field.label}
              </label>
              <input
                type={field.type}
                name={field.name}
                id={field.name}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                placeholder={field.placeholder}
                value={formik.values[field.name]}
                onChange={(e) => {
                  if (field.name === 'mobile' || field.name === 'pinCode') {
                    // Allow only numeric input
                    const value = e.target.value.replace(/[^0-9]/g, '');
                    formik.setFieldValue(field.name, value);
                  } else {
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched[field.name] && formik.errors[field.name] && (
                <div className="text-red-500 text-sm">{formik.errors[field.name]}</div>
              )}

            </div>
          ))}






          <div>
            <label
              htmlFor="state"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
            >
              State
            </label>
            <select
              id="state"
              name="state"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
              value={formik.values.state}
              onChange={handleStateChange}
              onBlur={formik.handleBlur}
            >
              <option value="">Select State</option>
              {states.map((state) => (
                <option key={state} value={state.name}>
                  {state.name}
                </option>
              ))}
            </select>
            {formik.touched.state && formik.errors.state && (
              <div className="text-red-500 text-sm">{formik.errors.state}</div>
            )}
          </div>

          {/* City Dropdown */}
          <div>
            <label
              htmlFor="city"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
            >
              City
            </label>
            <select
              id="city"
              name="city"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
              value={formik.values.city}
              onChange={handleCityChange}
              onBlur={formik.handleBlur}
              defaultValue={initialValues.city}
            >
              <option value="">{initialValues.city}</option>
              {cities.map((city, i) => (
                <option key={i} value={city.name}>
                  {city.name}
                </option>
              ))}
            </select>
            {formik.touched.city && formik.errors.city && (
              <div className="text-red-500 text-sm">{formik.errors.city}</div>
            )}
          </div>

        </div>

        <div className="xl:flex lg:flex md:flex  flex sm:flex justify-end gap-4 pt-5 xl:pt-0 lg:pt-0">
          <Button
            type="button"
            className="border-custom-red border hover:border-custom-red w-full xl:mt-0 lg:mt-0  hover:text-white text-custom-red font-bold py-2 px-4 rounded-lg hover:bg-custom-red transition duration-300"
            text="Cancel"
            onClick={handleCancel}
          />
          <Button
            type="submit"
            className="bg-custom-red w-full border text-white font-bold py-2 px-4 rounded-lg hover:text-custom-red hover:bg-transparent hover:border-custom-red hover:border transition duration-300"
            text="Save Changes"
          />
        </div>
      </form>
    </div>
  );
};

export default PersonalInfo;
