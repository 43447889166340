import React, { useState } from "react";
import BackgroundImage from "../../assets/images/img/SIGNUP-BG.png";
import LoginImg from "../../assets/images/img/login.png";
import Button from "../Common/Button";
import { useNavigate, NavLink } from "react-router-dom";
import useNetworkServices from "../../Networking/baseAPIManager";
import { HeaderLogo } from "./HeaderLogo";
import { showToast } from "../Common/Toastify";

const ForgotPassword = () => {
  const [formData, setFormData] = useState({ email: "" });
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { ForgotPassword } = useNetworkServices();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email } = formData;
    localStorage.setItem("forgotPasswordEmail", email);
    if (!email) {
      setError("Email is required");
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Email address is invalid");
      return;
    }

    setError("");

    try {
      const response = await ForgotPassword(formData);
      console.log(response.data, "ResponseData");
      navigate("/otpvarification");
    } catch (error) { 
      const apiMessage =
        error.response?.data?.message || "Something went wrong. Please try again.";
      showToast(apiMessage, "error");
    }
  };

  return (
    <>
      <div
        className="items-center justify-center min-h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      >
         
          <HeaderLogo />
         
        <div className="flex items-center justify-center  ">
          <div className="container p-8  lg:m-0 md:m-0">
            <div className="grid grid-cols-12 gap-8">
              <div className="col-span-12 md:col-span-6 lg:col-span-6 flex items-center justify-center">
                <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-white dark:border-gray-700">
                  <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-800">
                      Forgot your password?
                    </h1>
                    <p className="text-gray-400 text-sm">
                      Please enter your email address below, and we'll send you a link to reset your password.
                    </p>
                    <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" onSubmit={handleSubmit}>
                      <div>
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800"
                        >
                          Your email <span className="text-red-600">*</span>
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none dark:placeholder-gray-400 dark:text-gray-800"
                          placeholder="Enter.."
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
                      </div>
                      <Button
                        type="submit"
                        className={
                          "bg-custom-blue w-full text-white font-bold py-2 px-4 rounded hover:bg-custom-blue2 transition duration-300"
                        }
                        text={"Reset Password"}
                      />  
                      <p className="text-sm font-light">
                        <span className=" text-gray-500 dark:text-gray-600"> Already have an account?</span>
                        <NavLink
                          to="/signin"
                          className="font-medium text-primary-600 text-blue-400 ps-2 hover:underline dark:text-blue-500"
                        >
                          Login
                        </NavLink>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 lg:col-span-6 flex items-center justify-center">
                <img src={LoginImg} alt="Login" className="max-w-full h-auto" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
