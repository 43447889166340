import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, useLocation } from 'react-router-dom';
import './index.css'
import "./assets/styles/Style.css";
const root = ReactDOM.createRoot(document.getElementById('root'));


const ScrollToTop = () => {
  const { pathname } = useLocation(); // Use hook inside component

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top whenever pathname changes
  }, [pathname]);

  return null; // No UI to render
};

root.render(

  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>
);

reportWebVitals();
