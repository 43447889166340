 
import React, { useState } from "react";
import "../../assets/styles/Navbar.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocation, matchPath } from "react-router-dom";
import {
  LuLayoutDashboard,
  LuUserCircle,
  LuCircleDollarSign,
  LuRepeat,
  LuWallet,
  LuHelpCircle,
  LuLogOut,
  LuClipboardSignature,
} from "react-icons/lu";
import { IoCreateOutline } from "react-icons/io5";
import { CiMenuFries } from "react-icons/ci";
import { BiSolidOffer, BiWindows } from "react-icons/bi";

import { ClearLocalData } from "../../Networking/localStorageHelper";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const activeClass = " bg-white/50 !text-white  "; 
  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    let windowConfirm = window.confirm("Are you Sure you want to logout ");
    if (!windowConfirm) {
      return;
    }
    ClearLocalData("");
    window.location.href = "/signin";
  };
  const location = useLocation();
  const isCashFuseActive = matchPath(
    { path: "/cashFuseOffer" },
    location.pathname
  );
  const isAddOfferActive = matchPath({ path: "/addOffer" }, location.pathname);
  const isActive = isCashFuseActive || isAddOfferActive;
  const kycStatus = localStorage.getItem("userKyc");
  return (
    <>
      <div
        id="mySidebar"
        className={`sidebar ${isOpen ? "" : "closed"} ${
          !isOpen && window.innerWidth <= 768 ? "open" : ""
        }`}
      >
        <div className="sidebar-header">
          <button className="toggle-btn" onClick={toggleNav}>
            <CiMenuFries />
          </button>
        </div>

        <NavLink
          to="/dashboard"
          className={({ isActive }) =>
            `${isActive ? activeClass : ""} ${
              !kycStatus ? "disabled-link" : ""
            }`
          }
        >
          <LuLayoutDashboard /> <span className="ps-3"> Dashboard</span>
        </NavLink>

        <NavLink
          to="/MyProfile"
          className={({ isActive }) =>
            `${isActive ? activeClass : ""} ${
              !kycStatus ? "disabled-link" : ""
            }`
          }
        >
          <LuUserCircle /> <span className="ps-3"> My Profile</span>
        </NavLink>
        {kycStatus ? (
          <NavLink
            to="/kycComponents"
            className={({ isActive }) => (isActive ? activeClass : "")}
          >
            <LuClipboardSignature /> <span className="ps-3"> KYC</span>
          </NavLink>
        ) : (
          ""
        )}
        <NavLink
          to="/cashfuseCustomer"
          className={({ isActive }) =>
            `${isActive ? activeClass : ""} ${
              !kycStatus ? "disabled-link" : ""
            }`
          }
        >
          <LuCircleDollarSign />{" "}
          <span className="ps-3"> CashFuse Customer</span>
        </NavLink>
        <NavLink
          to="/myTransection"
          className={({ isActive }) =>
            `${isActive ? activeClass : ""} ${
              !kycStatus ? "disabled-link" : ""
            }`
          }
        >
          <LuRepeat /> <span className="ps-3"> My Transection</span>
        </NavLink>
        <NavLink
          to="/wallet"
          className={({ isActive }) =>
            `${isActive ? activeClass : ""} ${
              !kycStatus ? "disabled-link" : ""
            }`
          }
        >
          <LuWallet /> <span className="ps-3"> Wallet</span>
        </NavLink>
 
        <NavLink
          to="/cashFuseOffer"
          className={
            isActive ? `${activeClass} flex items-center` : "flex items-center"
          }
        >
          <BiSolidOffer />
          <span className="ps-3">CashFuse Offer</span>
        </NavLink>
        <NavLink
          to="/createbanner"
          className={({ isActive }) =>
            `${isActive ? activeClass : ""} ${
              !kycStatus ? "disabled-link" : ""
            }`
          }
        >
          <IoCreateOutline />
          <span className="ps-3"> Create Banner</span>
        </NavLink>
        

        <NavLink
          to="/conditions"
          className={({ isActive }) =>
            `${isActive ? activeClass : ""} ${
              !kycStatus ? "disabled-link" : ""
            }`
          }
        >
          <BiWindows /> <span className="ps-3"> Terms & Conditions</span>
        </NavLink>
        <NavLink
          to="/help"
          className={({ isActive }) =>
            `${isActive ? activeClass : ""} ${
              !kycStatus ? "disabled-link" : ""
            }`
          }
        >
          <LuHelpCircle />
          <span className="ps-3"> Help</span>
        </NavLink>
        <NavLink onClick={handleLogout}>
          <LuLogOut /> <span className="ps-3"> Logout</span>
        </NavLink>
      </div>
    </>
  );
};

export default Sidebar;
