
import './App.css';
import AppRoutes from './Routes/AppRoutes';
import React, {  useState } from 'react';
import { Toastify } from './Components/Common/Toastify'; 

function App() {
  const [profileImage, setProfileImage] = useState(null);
  const [profileName, setProfileName] = useState('');
  const handleProfileUpdate = (profileData) => {
    setProfileImage(profileData?.profileImage
      );
    setProfileName(`${profileData?.firstName} ${profileData?.lastName}`);
  };
  

  return (
    <>

      <Toastify /> 
      <AppRoutes onProfileUpdate={handleProfileUpdate} profileImage={profileImage} profileName={profileName} />

    </>
  );
}

export default App;
